import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';
import axios from 'axios';
import { getProfileAdmin, getProfileStudent, getProfileTutor } from '../../utils';

export const resetAppointmentNotification = createAsyncThunk('notification/resetAppointments', async (_, { rejectWithValue }) => {
	try {
		// const response = await axiosConfig.post('/notification/reset/appointments', {
		// 	firstName: firstName,
		// 	lastName: lastName,
		// 	email: email,
		// 	password
		// }, {
		// 	withCredentials: false
		// });

		return true
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

