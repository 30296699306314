import { Controller, useForm, useFormContext } from "react-hook-form"
import { FileInput, PhoneInput, SelectInput, TextInput, TextareaInput } from "../../../inputs"
import { useDispatch, useSelector } from 'react-redux';
import countries from "../../../../utils/data/countries.json"
import { StepNavigation } from "../navigation";
import { useRef, useState } from "react";
import { fillTutorInformations } from "../../../../store/profile/profileActions";

const TutorInformations = ({ setCurrentStep, control, setActiveTab }) => {
    const formRef = useRef()
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue
    } = useFormContext();

    const onSubmit = async (data) => {
        setIsLoading(true);
        await dispatch(fillTutorInformations(data))
            .then((data) => {
                setIsLoading(false);
                setCurrentStep(3);
            });
    };

    const nextStep = () => {
        formRef.current.requestSubmit();
    }
    const options = countries.map((country) => { return { value: country.iso, label: country.name } })

    return (
        <form ref={formRef} id="teacher-informations" className="mx-auto flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            <FileInput
                name="avatar"
                label="Avatar"
                errors={errors}
                register={register}
                control={control}
                style="avatar"
                required
                validationSchema={{
                    required: "This field is required",
                }}
                defaultValue={watch("avatar")}
            />
            <div className="flex gap-10 mt-5">
                <TextInput
                    name="firstName"
                    label="First Name"
                    errors={errors}
                    register={register}
                    control={control}
                    validationSchema={{
                        required: "This field is required"
                    }}
                />
                <TextInput
                    name="lastName"
                    label="Last Name"
                    errors={errors}
                    register={register}
                    control={control}
                    validationSchema={{
                        required: "This field is required"
                    }}
                />
            </div>
            <Controller
                control={control}
                name={"country"}
                render={({ field }) => (
                    <SelectInput
                        name="country"
                        label="Country"
                        errors={errors}
                        register={register}
                        control={control}
                        options={options}
                        onChangeSelect={event => {
                            field.onChange(event.value)
                        }}
                        required
                        validationSchema={{
                            required: "Country field is required",
                        }}
                    />
                )}
            />
            <PhoneInput
                name="phone"
                label="Phone"
                errors={errors}
                register={register}
                control={control}
            />

            <StepNavigation moveBack={() => setCurrentStep(0)} moveForward={nextStep} isLoading={isLoading} />
            <span className="pb-[210px]"></span>
            {/* <div className={Styles.navigation}>
                <button>
                    <FiArrowRight color="#fff" fontSize={20} />
                </button>
            </div> */}

        </form>
    )
}

export { TutorInformations }