import { useLocation } from "react-router"
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { usePagination } from "@table-library/react-table-library/pagination";
import Styles from '../users/users.module.scss'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiArrowDown, FiArrowUp, FiBookOpen, FiCreditCard, FiDollarSign, FiEye, FiFilter, FiPlus, FiSettings, FiSliders, FiUsers } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { resetAppointmentNotification } from "../../store/notification/notificationActions";
import { getAppointmentList } from "../../store/appointment/appointmentActions";
import { formatDateToUserTZ, getImage, getProfileAdmin } from "../../utils";
import moment from "moment";
import { acceptWithdraw, addWithdrawRequet, getAllWithdraw, getWithdrawList, rejectWithdraw } from "../../store/wallet/walletActions";
import { NumberIcon } from "../../components/widgets";
import { TfiWallet } from "react-icons/tfi";
import { TextInput } from "../../components/inputs";
import { useForm } from "react-hook-form";

const Withdraw = () => {
    const [filter, setFilter] = useState([]);
    const [isRequest, setIsRequest] = useState(false);
    const { currentProfile } = useSelector(state => state.profile)
    const { user } = useSelector((state) => state.auth)

    const dispatch = useDispatch()
    useEffect(() => {
        initView()
    }, [])

    const TransactionAmount = ({ type, amount }) => {
        if ([-1, 1].includes(type)) {
            return <div className="text-nt-blue flex">
                <FiArrowUp className="text-nt-blue" size={20} />
                {amount + " €"}
            </div>
        } else if ([-2, 0].includes(type)) {
            return <div className="text-nt-red flex">
                <FiArrowDown className="text-nt-red" size={20} />
                {amount + " €"}
            </div>
        }
    }
    const [nodes, setNodes] = useState([]);

   

    const COLUMNS = [
        {
            label: 'Request Date',
            renderCell: (item) => <p className="capitalize font-medium text-nt-blue">{(formatDateToUserTZ(item.createdAt, user.timezone))}</p>,
            pinLeft: true,

        },
        {
            label: 'Amount',
            renderCell: (item) => item.amount + "€",
            pinLeft: true,

        },
        {
            label: 'Payal Email',
            renderCell: (item) => item.email,
            pinLeft: true,
        },
        {
            label: 'Status',
            renderCell: (item, index) => {
                return <StatusTag status={item.status} />
            },
            pinLeft: true,

        },
    ];

    if (currentProfile.role === 1) {
        COLUMNS.unshift({
            label: 'Tutor',
            renderCell: (item, index) => {
                return <div className="flex gap-2 capitalize text-nt-blue items-center">
                    {item.tutor?.userId?.avatar?.path ? <img className={Styles.avatar} src={getImage(item.tutor?.userId?.avatar?.path)} /> : ''}
                    {item.tutor?.userId ? item.tutor?.userId?.firstName + " " + item.tutor?.userId?.lastName : ''}
                </div>
            },
            pinLeft: true,
        })
        COLUMNS.push({
            label: 'Action',
            renderCell: (item, index) => {
                return <>{item.status == -1 ? <div className="flex gap-1">
                    <button type="button" className="rounded-md bg-nt-blue text-white px-2 py-1" onClick={() => handleAcceptWithdraw(item._id)}>Accept</button>
                    <button type="button" className="rounded-md bg-nt-red text-white px-2 py-1" onClick={() => handleRejectWithdraw(item._id)}>Reject</button>
                </div> : ''}</>
            },
            pinLeft: true,
        })
    }
    const handleAcceptWithdraw = (id) => {
        dispatch(acceptWithdraw(id))
            .unwrap()
            .then(() => {
                setNodes((n) => n.map(k => ({ ...k, status: k._id === id ? 1 : k.status })))
            })
    }
    const handleRejectWithdraw = (id) => {
        dispatch(rejectWithdraw(id))
            .unwrap()
            .then(() => {
                setNodes((n) => n.map(k => ({ ...k, status: k._id === id ? 0 : k.status })))
            })
    }

    const StatusTag = ({ status }) => {
        if (status === -1) {
            return <span className={`bg-nt-blue text-white px-2 rounded py-1 inline-block`}>Pending</span>
        } else if (status === 1) {
            return <span className={`bg-green text-white px-2 rounded py-1 inline-block`}>Accepted</span>
        } else if (status === 0) {
            return <span className={`bg-red text-white px-2 rounded py-1 inline-block`}>Rejected</span>
        }
    }
    const theme = useTheme([getTheme(), {
        HeaderRow: `color: #191D51;`,
        Table: `--data-table-library_grid-template-columns: ${currentProfile.role == 1 ? 'auto auto' : ''} auto auto auto auto ; width:auto;min-width:auto;min-height:auto;`
    }]);

    let data = { nodes };

    data = {
        nodes: data.nodes.filter((item) =>
            (filter.length && filter.includes(item.status) || filter.length === 0)
        ),
    };

    const pagination = usePagination(data, {
        state: {
            page: 0,
            size: 5,
        },
        onChange: onPaginationChange,
    });

    const pageButtonCount = 5;
    const [minVisibleBtn, setMinVisibleBtn] = useState(0)
    const [maxVisibleBtn, setMaxVisibleBtn] = useState(pageButtonCount)
    function onPaginationChange(action, state) {
        console.log(action, state);
        // let canShow = (index + pagination.state.page >= Math.floor(maxPageButton/2) || index + pagination.state.page == 0) && index + pagination.state.page + maxPageButton <= Math.floor(maxPageButton/2)
        setMinVisibleBtn(state.page - Math.floor(pageButtonCount / 2) >= 0 ? state.page - Math.floor(pageButtonCount / 2) : 0)
        setMaxVisibleBtn((minVisibleBtn + pageButtonCount) - 1)
    }

    const toggleFilter = (status) => {
        pagination.fns.onSetPage(0)
        if (filter.includes(status))
            setFilter(x => x.filter(v => v != status))
        else
            setFilter(x => [...x, status])
    }

    const adminProfile = getProfileAdmin();


    const WidthrawList = () => {
        return <div className="w-full">
            <div className="bg-white p-4 rounded-2xl">
                <div className={Styles.tableHeader+ " items-center"}>
                    <h2>Withdraw List</h2>

                    {currentProfile.role !== 1 && <button className="bg-nt-blue rounded text-white pl-2 pe-3 py-2 ml-2 flex items-center gap-2" onClick={() => setIsRequest(true)}><FiPlus /> Add New Request</button>}
                    {currentProfile.role === 1 && <div className="flex gap-1 mb-5 items-center">
                    <FiFilter className="text-nt-blue mr-2" size={18} />
                    <span onClick={() => toggleFilter(-1)} className={`${filter.includes(-1) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Pending</span>
                    <span onClick={() => toggleFilter(1)} className={`${filter.includes(1) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Accepted</span>
                    <span onClick={() => toggleFilter(0)} className={`${filter.includes(0) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Rejected</span>
                   
                </div>}
                </div>

                {currentProfile.role !== 1 && <div className="flex gap-1 mb-5 items-center">
                    <FiFilter className="text-nt-blue mr-2" size={18} />
                    <span onClick={() => toggleFilter(-1)} className={`${filter.includes(-1) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Pending</span>
                    <span onClick={() => toggleFilter(1)} className={`${filter.includes(1) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Accepted</span>
                    <span onClick={() => toggleFilter(0)} className={`${filter.includes(0) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Rejected</span>
                   
                </div>}
                <CompactTable columns={COLUMNS} layout={{ custom: true, horizontalScroll: true, }} data={data} theme={theme} pagination={pagination} />
                <div className={Styles.pagination}>
                    <div className={Styles.paginationInfos}>
                        Total pages: {pagination.state.getTotalPages(data.nodes)}
                    </div>
                    <div className={Styles.paginationButtons}>
                        <button
                            type="button"
                            disabled={minVisibleBtn === 0}
                            onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
                        >
                            {"<"}
                        </button>
                        {pagination.state.getPages(data.nodes).map((_, index) => {
                            return (minVisibleBtn <= index && maxVisibleBtn >= index) && <button
                                key={index}
                                type="button"
                                className={pagination.state.page === index ? Styles.active : ''}
                                onClick={() => pagination.fns.onSetPage(index)}
                            >
                                {index + 1}
                            </button>

                        })}
                        <button
                            type="button"
                            onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
                            disabled={pagination.state.page + Math.floor(pageButtonCount / 2) > pagination.state.getTotalPages(data.nodes)}
                        >
                            {">"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }

    const { handleSubmit, reset, formState: { errors }, register, control, watch } = useForm()
    const [isDisabled, setIsDisabled] = useState(false)
    const onAddRequest = (data) => {
        console.log(data)
        setIsDisabled(true);
        dispatch(addWithdrawRequet(data))
            .unwrap()
            .then(() => {
                initView()
                setIsDisabled(false)
                setIsRequest(false)
                reset()
            })
    }

    const initView = () => {
        if (currentProfile.role == 1) {
            dispatch(getAllWithdraw())
                .unwrap()
                .then((v) => setNodes(v))
        } else {

            dispatch(getWithdrawList())
                .unwrap()
                .then((v) => setNodes(v))
        }
        dispatch(resetAppointmentNotification())
    }

    // const amount = watch("amount")
    useEffect(() => {
        console.log(errors)

    }, [errors])
    const { balance } = useSelector((state) => state.wallet);
    const WithdrawRequest = () => {

        return <div className="bg-white rounded-2xl p-4 mx-auto w-1/2">
            <h2 className="text-nt-blue mb-5">New Widthdraw Request</h2>
            <form onSubmit={handleSubmit(onAddRequest)}>
                <TextInput
                    name="email"
                    label="Email"
                    type="email"
                    errors={errors}
                    register={register}
                    control={control}
                    validationSchema={{
                        required: "This field is required"
                    }}
                />
                <div className="flex items-center gap-5">
                    <TextInput
                        name={`amount`}
                        label={"Amount"}
                        errors={errors}
                        inputClassname="w-full"
                        placeholder="10"
                        type="number"
                        register={register}
                        minHtml={10}
                        maxHtml={balance}
                        control={control}
                        validationSchema={{
                            required: "This field is required",
                            validate: (value) => {
                                return value < 10 ? "Minimum value is 10" : value > balance ? "Maximum value is " + balance : true;
                            }
                        }}
                    />

                    {/* <div className="flex-1">
                        <label>Amount *</label>
                        <input {...register("amount", {
                            required: "This field is required",
                            // validate: (value) => {
                            //     return value < 10 ? "Minimum value is 10" : value > balance ? "Maximum value is " + balance : true;
                            // }
                        })} className="w-full border-b-[1px] border-nt-grey mb-5" type="number" {...register("amount")}></input>
                    </div> */}
                    <span className="p-1 px-2 rounded-md text-center block bg-nt-grey text-white">Your balance {balance}€</span>
                </div>

                <div className="flex gap-2 justify-center">
                    <button disabled={isDisabled} className="bg-nt-blue w-36 text-white p-2 rounded">Send Request</button>
                    <button type="button" onClick={() => setIsRequest(false)} className="w-36 bg-nt-red text-white p-2 rounded">Cancel</button>
                </div>
            </form>
        </div>
    }

    return (
        <>
            {!isRequest ? <WidthrawList /> : <WithdrawRequest />}
        </>

    )
}

export { Withdraw }