import SelectItemImg from '../../assets/chat/select-conversation.png'
import { useDispatch, useSelector } from 'react-redux';
import { VirtuosoMsg } from './vituoso';
import { useMemo } from 'react';

const ChatBox = ({ pushFile }) => {
    const dispatch = useDispatch();
    const currentChat = useSelector((state) => state.chat.currentChat)
    console.log("chatbox")
    const NoChatSelected = useMemo(() => <div className="flex items-center place-content-center flex-col opacity-40">
        <img src={SelectItemImg} />
        <h3>Please select conversation</h3>
    </div>, [])

    const SingleChat = useMemo(() => () => { return <VirtuosoMsg messagesList={currentChat?.messages || []} /> }, [currentChat?._id | ""])

    const Chat = () => {
        return (
            <div className='w-full h-full flex flex-col justify-end'>
                <VirtuosoMsg messagesList={currentChat?.messages || []} />
            </div>
        )
    }

    return <div className="w-full h-full grow">
        {currentChat ? <Chat /> : NoChatSelected}
    </div>


}

export { ChatBox }