import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import moderateReducer from './moderate/moderateSlice';
import profileReducer from './profile/profileSlice';
import chatReducer from './chat/chatSlice';
import appointmentReducer from './appointment/appointmentSlice';
import notificationReducer from './notification/notificationSlice';
import walletReducer from './wallet/walletSlice';
import { injectStore } from '../utils/axiosConfig';

const store = configureStore({
	reducer: {
		auth: authReducer,
		user: userReducer,
		moderate: moderateReducer,
		profile: profileReducer,
		chat: chatReducer,
		appointment: appointmentReducer,
		notification: notificationReducer,
		wallet: walletReducer,
	}
});

injectStore(store);

export default store;
