import { useEffect, useState } from "react"
import { dateToServerFormat, dateToSlice, formatTimeZone, getImage, getMomentDate } from "../../utils"
import moment from "moment"
import { Controller, useForm } from "react-hook-form"
import Calendar from "react-calendar"
import ReactSelect from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { activateRange, disableRange, getDayAvailability } from "../../store/appointment/appointmentActions"
import { FiArrowDown } from "react-icons/fi"
import { CancelRangeEvent } from "../../components/appointment/cancelRange"
import { RescheduleRangeEvent } from "../../components/appointment/rescheduleRange"

const RangeEventSelected = ({ currentEvent, events = [], refreshCalendar, setIsOpen }) => {
    const [confirmAction, setConfirmAction] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState("");
    const [isReschedule, setIsReschedule] = useState(false);
    const [isEnabling, setIsEnabling] = useState(false);
    const [isDisabling, setIsDisabling] = useState(false);
    const [availableOptions, setAvailableOptions] = useState([]);
    const { user } = useSelector((state) => state.auth)
    const { currentProfile } = useSelector((state) => state.profile)
    const resetConfirm = () => {
        setIsConfirm(false);
        setConfirmMessage("");
        setConfirmAction(null)
    }

    useEffect(() => {
        setIsDisabling(false)
    }, [currentEvent.startStr, currentEvent.endStr])
    const dispatch = useDispatch();

    const IssueOptions = [
        { value: 1, label: "Health issues" },
        { value: 2, label: "I am taking leave" },
        { value: 3, label: "I wish to change my rates" },
        { value: 4, label: "I have another commitment" },
        { value: 5, label: "I have a technical problem" },
        { value: 6, label: "Other" },
    ]

    const getDayAvailabilityAction = (day) => {
        setAvailableOptions([]);
        dispatch(getDayAvailability({
            profile: currentProfile._id,
            day: dateToServerFormat(day, user.timezone)
        }))
            .unwrap()
            .then(v => {

                let slices = v.map((slice => {
                    return {
                        value: getMomentDate(slice.date),
                        label: dateToSlice(slice.date)
                    };
                }))
                console.log(slices)
                setAvailableOptions(slices)
            })
    }

    const handleCancelCourse = () => {
        setIsCancel(true)
    }

    const showConfirmMessage = (message, action) => {
        setConfirmMessage(message)
        setConfirmAction(action)
        setIsConfirm(true)
    }

    const { handleSubmit, control, watch, register, setValue } = useForm();

    const Appointment = ({ event }) => {
        return <div className='flex items-center gap-2'>
            <img className='w-10 h-10 rounded-full object-cover' src={getImage(event.avatar)} />
            <div className=''>
                <h4 className='capitalize text-nt-blue'>{event.title}</h4>
                <span className='text-nt-grey'>{moment(event.date).parseZone().format("YYYY-MM-DD HH:mm").toString()}</span>
            </div>
        </div>
    }

    const handleActivateRange = () => {
        const userTz = formatTimeZone(user.timezone)
        console.log(moment(currentEvent.start).parseZone())
        dispatch(activateRange({
            start: moment(currentEvent.startStr).parseZone().format("YYYY-MM-DDTHH:mm:ss") + userTz,
            end: moment(currentEvent.endStr).parseZone().format("YYYY-MM-DDTHH:mm:ss") + userTz,
        }))
            .unwrap()
            .then((v) => {
                refreshCalendar()
                setIsEnabling(false)
                setIsOpen(false)
            })
    }

    const handleDisableRange = () => {
        const userTz = formatTimeZone(user.timezone)
        dispatch(disableRange({
            start: moment(currentEvent.startStr).parseZone().format("YYYY-MM-DDTHH:mm:ss") + userTz,
            end: moment(currentEvent.endStr).parseZone().format("YYYY-MM-DDTHH:mm:ss") + userTz,
        }))
            .unwrap()
            .then((v) => {
                setIsDisabling(false);
                setIsOpen(false)
                refreshCalendar()
            })
    }

    return <div>
        <div className="flex flex-col mb-4 ">
            <h4 className='text-nt-blue'>From: {moment(currentEvent.startStr).parseZone().format("YYYY-MM-DD HH:mm").toString()}</h4>
            <h4 className='text-nt-blue'>To: {moment(currentEvent.endStr).parseZone().format("YYYY-MM-DD HH:mm").toString()}</h4>
        </div>
        {(isDisabling && !isReschedule) ? events.map((ev, i) => {
            return <div key={i}>
                <Appointment event={ev} key={ev._id} />
            </div>
        }) : ""}

        {(!isDisabling && !isReschedule && !isEnabling && !isCancel) && <div className="flex flex-col justify-center gap-5 mt-5">
            <button className="bg-nt-blue text-white px-2 py-3 rounded w-full" onClick={() => { setIsEnabling(true) }}>Activate</button>
            <button className="bg-nt-red text-white px-2 py-3 rounded w-full" onClick={() => { setIsDisabling(true) }}> Deactivate</button>
            <button className="bg-nt-grey text-white px-2 py-3 rounded w-full" onClick={() => { setIsOpen(false) }}> Cancel</button>
        </div>}

        {(isDisabling && !isReschedule && events.length > 0 && !isCancel) && <div className="flex flex-col justify-center gap-5 mt-5">
            <button className="bg-nt-blue text-white px-2 py-3 rounded w-full" onClick={() => { setIsReschedule(true); getDayAvailabilityAction(moment().add(1, "day").toDate()) }}>Reschedule Courses</button>
            <button className="bg-nt-red text-white px-2 py-3 rounded w-full" onClick={() => handleCancelCourse()}>Cancel Courses</button>
            <button className="bg-nt-grey text-white px-2 py-3 rounded w-full" onClick={() => setIsDisabling(false)}>Cancel Deactivate</button>
        </div>}

        {
            (isEnabling) && <div className="flex flex-col justify-center gap-5 mt-5">
                <h4 className="text-nt-blue text-center">Are you sure to activate this range?</h4>
                <button className="bg-nt-red text-white px-2 py-3 rounded w-full" onClick={() => handleActivateRange()}>Yes</button>
                <button className="bg-nt-grey text-white px-2 py-3 rounded w-full" onClick={() => setIsEnabling(false)}>No</button>
            </div>
        }
        {
            (isDisabling && events.length === 0) && <div className="flex flex-col justify-center gap-5 mt-5">
                <h4 className="text-nt-blue text-center">Are you sure to deactivate this range?</h4>
                <button className="bg-nt-red text-white px-2 py-3 rounded w-full" onClick={() => handleDisableRange()}>Yes</button>
                <button className="bg-nt-grey text-white px-2 py-3 rounded w-full" onClick={() => setIsDisabling(false)}>No</button>
            </div>
        }
        {
            isCancel && <CancelRangeEvent setIsOpen={(v) => setIsOpen()} setIsCancel={() => setIsCancel()} currentEvent={currentEvent} events={events} refreshParent={() => refreshCalendar()}
            setIsDisabling={(v) => {setIsDisabling(v); setIsOpen(false)}}  />
        }
        {
            isReschedule && <RescheduleRangeEvent setIsOpen={(v) => setIsOpen(v)} setIsReschedule={(v) => setIsReschedule(v)} setIsDisabling={(v) => setIsDisabling(v)} key={events} currentEvent={currentEvent} events={events} refreshParent={()=> refreshCalendar()}/>
        }


    </div>
}

export { RangeEventSelected }