import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getTransactionList = createAsyncThunk('wallet/transaction/list', async (global = false, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/wallet/transaction/list', {
			profile: getState().profile.currentProfile._id,
			global: global 
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getWalletStats = createAsyncThunk('wallet/stats', async (global = false, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/wallet/stats', {
			profile: getState().profile.currentProfile._id,
			role: getState().profile.currentProfile.role,
			global: global
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const addWithdrawRequet = createAsyncThunk('wallet/withdraw/request', async ({amount, email}, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/wallet/withdraw/request', {
			tutor: getState().profile.currentProfile._id,
			amount: amount,
			email: email
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getWithdrawList = createAsyncThunk('wallet/withdraw/list', async (global = false, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/wallet/withdraw/list', {
			tutor: getState().profile.currentProfile._id,
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getAllWithdraw = createAsyncThunk('wallet/withdraw/all', async (global = false, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/wallet/withdraw/all', {
			profile: getState().profile.currentProfile._id,
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const acceptWithdraw = createAsyncThunk('wallet/withdraw/accept', async (id, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/wallet/withdraw/accept', {
			profile: getState().profile.currentProfile._id,
			id: id
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const rejectWithdraw = createAsyncThunk('wallet/withdraw/reject', async (id, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.post('/wallet/withdraw/reject', {
			profile: getState().profile.currentProfile._id,
			id: id
		});

		return response.data
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});
