import { FiBookOpen, FiCreditCard, FiDollarSign, FiUsers } from 'react-icons/fi'
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaListCheck } from "react-icons/fa6";
import { AppointmentWidget, BarChart, NumberIcon } from '../../components/widgets'
import Styles from './home.module.scss'
import GlobalStyles from '../../utils/sass/global.module.scss'
import Chart from 'react-google-charts';
import { Messages } from '../../components/chat';
import { Calendar } from '../appointment/calendar';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardStat } from '../../store/profile/profileActions';
import { useEffect } from 'react';

const DashboardStudent = () => {
    const userChartData = [
        ["Month", "Tutors", "Students"],
        ["Jan", 200, 400],
        ["Feb", 214, 400],
        ["Mar", 12, 400],
        ["Apr", 1000, 400],
        ["May", 84, 400],
        ["June", 15, 400],
        ["July", 290, 400],
        ["Aug", 140, 400],
        ["Sept", 320, 400],
        ["Oct", 540, 400],
        ["Nov", 105, 460],
        ["Dec", 209, 1120]
    ];

    const usersChartOptions = {
        chart: {
            title: "Company Performance",
            subtitle: "Sales, Expenses, and Profit: 2014-2017",
            isStacked: true,
        },
        colors: ["#DF2B47", "#E0BD48", "#191D51"],
        isStacked: true,
    };


    const data = [
        ["Language", "Speakers"],
        ["German", 5.85],
        ["French", 1.66],
        ["Italian", 0.316],
        ["Romansh", 0.0791],
    ];

    const options = {
        legend: "none",
        pieSliceText: "label",
        pieStartAngle: 100,
    };

    const {stats} = useSelector(state => state.profile)    
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getDashboardStat())
        .unwrap()
        .then(v => {
            console.log(v)
        })
    },[])
    const {pending} = useSelector(state => state.wallet)

    return (
        <>
            <h1 className={GlobalStyles.pageTitle}>Dashboard</h1>
            <div className="general-informations mb-5 grid lg:grid-cols-4 sm:grid-cols-2 gap-4">
                <NumberIcon title="Lessons" number={stats.lessons} Icon={FiBookOpen} />
                <NumberIcon title="Next lessons" number={stats.pendinglessons} Icon={FiBookOpen} />
                <NumberIcon title="Attendance" number="100%" Icon={FaListCheck} />
                <NumberIcon title="Pending Payment" number={pending+ " €"} Icon={FiCreditCard} />
            </div>
            
            <div className="grid lg:grid-cols-6 gap-4">
                <div className="grid col-span-1 lg:col-span-4 bg-white p-5 rounded-xl">
                    <h2 className='text-nt-blue max-h-[50px]'>Appointments</h2>
                    <Calendar />
                </div>
                <div className="flex-col col-span-1 lg:col-span-2 bg-white p-5 flex items-start justify-start rounded-xl">
                    <h2 className='text-nt-blue max-h-[50px]'>Chat</h2>
                    <Messages search={false} />
                </div>
            </div>
        </>
    )
}

export { DashboardStudent }