import { useMemo } from "react";
import { getYoutubeID } from "../../utils";

const YoutubeEmbed = ({ url }) => useMemo(() => {
    const id = getYoutubeID(url)
    return (
        <div className="video-responsive">
            {id && <iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${id}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />}
        </div>
    )
}


);
export default YoutubeEmbed;