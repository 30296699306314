import Styles from '../../chat/chat.module.scss'
import GlobalStyle from '../../../utils/sass/global.module.scss'
import { ChatBox, Files, Messages } from '../../../components/chat';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getAdminSupport } from '../../../store/chat/chatActions';

const SupportPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [defaultChat, setDefalultChat] = useState(null);
    // const currentChat = useSelector(state => state.chat.currentChat)
    // const chats = useSelector(state => state.chat.chats)
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const { id } = useParams()
    
    useEffect(() => {
        setIsLoading(true)
        // elementRef.current.scrollIntoView({behavior: "smooth", block:"end"})
        console.log(id)
        if(id) {
            setDefalultChat(id)
        }
        dispatch(getAdminSupport())
            .unwrap()
            .then((v) => {
                setIsLoading(false)
            })
    }, []);

    return (
        <div className={Styles.chatPage}>
            <h1 className={GlobalStyle.pageTitle}>Chat</h1>

            <div className={Styles.container}>
                <div className={Styles.messages}>
                    <Messages defaultChat={defaultChat}/>
                </div>
                <div className={Styles.chat}>
                    {/* <ChatBox pushFile={(v) => setFiles(x => [...x, v])} /> */}
                    <ChatBox  />
                </div>
                <div className={Styles.files}>
                    <Files />
                </div>
            </div>
        </div>
    )
}

export default SupportPage;