import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { forgotPassword } from '../../store/auth/authActions';
import { errorToast } from '../../utils';

const ForgotPassword = () => {
	const { user, loading } = useSelector((state) => state.auth);
	const [mailSent, setMailSent] = useState(false)
	const dispatch = useDispatch();
	let email = useRef('latiri.w@gmail.com');


	const signinUser = async (e) => {
		e.preventDefault();

		dispatch(forgotPassword({
			email: email.value,
		}))
			.unwrap()
			.then(() => {
				setMailSent(true)
			})
			.catch((errorData) => {
				errorToast(errorData.error);
			});
	};

	const ForgotForm = () => {
		return (<form onSubmit={signinUser}>
			<h1 className='text-nt-blue text-center mb-5'>Forgot password</h1>
			{!mailSent && <div>
				<label className="block text-primary-grey text-[13px] font-medium pb-1">Email</label>
				<div className="relative">
					<input
						className="inputField w-full"
						name="email"
						placeholder="Enter your email"
						id="email"
						ref={(e) => { email = e; }}
						defaultValue={""}
						type="email"
						required />
				</div>
			</div>}
			{!mailSent && <button type="submit" className="primaryButton mt-4">
				{loading ? 'Loading...' : 'Submit'}
			</button>}
			{mailSent && <p className="text-center mt-4">
				Your password reset link has been sent successfully to your email.<br /> Kindly check your inbox and utilize the provided link to proceed with resetting your password.
			</p>}
			<p className='text-center mt-5'>If you remember your password<br /> you can <Link to='/login' className="link">login here.</Link>
			</p>
		</form>)
	}
	return (
		<>
			<Toaster />
			<ForgotForm />
		</>
	);
};

export { ForgotPassword }