import Styles from './inputs.module.scss';

const CheckboxInput = ({ name, label, register, errors, validationSchema,style }) => (
    <div className={Styles.checkboxInput}>

        <div className="relative text-left flex items-center" style={style}>

            <input
                className='inputField'
                id={name}
                name={name}
                type="checkbox"
                {...register(name, validationSchema)}

            />
            <label htmlFor={name} className='inline pl-2 text-left text-primary-grey text-[13px] font-semibold'>
                {label}
            </label>
        </div>
    </div>
);
export { CheckboxInput };