import { createSlice } from '@reduxjs/toolkit';
import { getChatFiles, getChatMessages, getConversationList, getFullChat, sendChatFile, sendChatMessage } from './chatActions';

const initialState = {
	chats: [],
	currentChat: null,
	files: [],
	loading: false,
	error: null,
	success: false,
};

const chatSlice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
		setCurrentChat: (state, action) => {
			state.currentChat = action.payload;
		}
	},
	extraReducers: {
		[getConversationList.fulfilled]: (state, action) => {
			state.chats = action.payload;
		},
		[getFullChat.fulfilled]: (state, action) => {
			state.currentChat = action.payload;
			state.files = action.payload.messages.filter(m => m.isFile)
		},
		[sendChatFile.fulfilled]: (state, action) => {
			if(action.payload.isFile)
				state.files.unshift(action.payload);
		},
		// [getChatFiles.fulfilled]: (state, action) => {
		// 	// if(action.payload.isFile)
		// 	state.files = action.payload
		// },
		// [registerUser.fulfilled]: (state, action) => {
		// 	state.loading = false;
		// 	console.log(action.payload)
		// 	state.user = action.payload;
		// 	state.error = null;
		// 	state.success = true;
		// },
	},
});

export default chatSlice.reducer;

export const { setCurrentChat } = chatSlice.actions;
