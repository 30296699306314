const ProfileWidget = () => {
    return (
        <>
            <div className="">
                <div className="">
                    <img src="http://127.0.0.1:3420/api/v1/files\6df946aca27735f891267.png" />
                    <h4>Wael Latiri</h4>
                    <img src={`https://flagsapi.com/TN/shiny/24.png`} />
                    <span>Phone: +216 24 252 265</span>
                    <span>Email: latiri.w@gmail.com</span>
                </div>
            </div>
            <div className="">
                Block user
            </div>
        </>
    )
}

export { ProfileWidget }