import Styles from './profile.module.scss'
import AvatarPlaceholder from '../../assets/auth/register/avatar-placeholder.png'
import { useMemo, useState } from 'react'
import YoutubeEmbed from './youtube';
import Langs from '../../utils/data/langs.json'
import { Link } from 'react-router-dom';

const SingleTutor = ({ filePreview=false, profile, activeTab, setActiveTab }) => {

    const getLangName = (key) => {
        return Langs.find(item => {
            return item.value == key
        })?.name;
    }

    const LangTag = ({ lang }) => {
        return (lang.name?.trim() !== "" && lang.level?.trim() !== "") ?
            <div className={Styles.lang}>
                <span className={Styles.langName}>{getLangName(lang.lang)}</span>
                <span className={Styles.langLevel}>{lang.level}</span>
            </div> : ''
    }

    return (
        <div className={Styles.profileContainer}>
            <div className={Styles.generalInformations}>
                <div className={Styles.leftSide}>
                    <div className={Styles.avatar}>
                        <img className='object-cover w-[100px] h-[100px]' src={(profile.avatar?.length > 0 && profile.avatar[0] instanceof Blob) ?
                            URL.createObjectURL(profile.avatar[0]) :
                            (profile.avatar?.length > 0 && !Array.isArray(profile.avatar)) ?
                                process.env.REACT_APP_BACKEND_URL + profile.avatar
                                : AvatarPlaceholder} width={100} />
                    </div>
                    <div className={Styles.infos}>
                        <h3 className='capitalize'>
                            {profile.firstName + " " + profile.lastName}
                            {(profile.country && profile.country?.length) && <img src={`https://flagsapi.com/${profile.country}/shiny/24.png`} />}
                        </h3>
                        {profile.teach && profile.teach.length && <div className={Styles.teaches}>
                            <div className={Styles.title}>Teaches:</div>
                            <div className={Styles.listContainer}>
                                {
                                    profile.teach.map((lang, i) => {
                                        return (
                                            <LangTag lang={lang} key={i} />
                                        )
                                    })
                                }
                            </div>
                        </div>}
                        {profile.speak && profile.speak.length && <div className={Styles.speaks}>
                            <div className={Styles.title}>Speaks:</div>
                            <div className={Styles.listContainer}>
                                {
                                    profile.speak.map((lang, i) => {
                                        return (
                                            <LangTag lang={lang} key={i} />
                                        )
                                    })
                                }
                            </div>
                        </div>}
                    </div>
                </div>
                <div className={Styles.rightSide}>
                    {profile.video?.length ? <YoutubeEmbed url={profile.video} /> : ""}
                </div>
            </div>
            <div className={Styles.tutorTabs}>
                <ul className={Styles.tabTitleContainer}>
                    <li onClick={() => setActiveTab(0)} className={`${Styles.tabTitle} ${activeTab === 0 && Styles.activeTab}`}>
                        About Me
                    </li>
                    <li onClick={() => setActiveTab(1)} className={`${Styles.tabTitle} ${activeTab === 1 && Styles.activeTab}`}>
                        Education
                    </li>
                    <li onClick={() => setActiveTab(2)} className={`${Styles.tabTitle} ${activeTab === 2 && Styles.activeTab}`}>
                        Work Experience
                    </li>
                    <li onClick={() => setActiveTab(3)} className={`${Styles.tabTitle} ${activeTab === 3 && Styles.activeTab}`}>
                        Certificates
                    </li>
                </ul>

                <div className={Styles.tabContent}>
                    {activeTab === 0 &&
                        <div className={Styles.tutorDescription} dangerouslySetInnerHTML={{ __html: profile.description }} />
                    }
                    {activeTab === 1 && <div className={Styles.education}>
                        {(!profile.noEducation && profile.formations?.length) && profile.formations.sort((a, b) => b.to - (a.toPresent ? 3000 : a.to)).map((formation, i) => {
                            const period = formation.from + " - " + (formation.toPresent ? ' Present' : formation.to);
                            return (<div className={Styles.formation} key={i}>
                                <div className={Styles.leftSide}>
                                    {formation.from != "" && period}
                                </div>
                                <div className={Styles.rightSide}>
                                    <h3>{formation.label}</h3>
                                    <h4>{formation.school}</h4>
                                    <p>{formation.description}</p>
                                </div>
                                {filePreview && <div className='w-[100px] bg-nt-blue rounded-md self-start text-center'>
                                    {(formation.file && formation.file.path) &&
                                        <a href={process.env.REACT_APP_BACKEND_URL + formation.file.path} className='text-white no-underline' target='_blank'>Show File</a>}
                                </div>}
                            </div>)
                        })}
                        {profile.noEducation && <h4>No education level</h4>}
                    </div>}
                    {activeTab === 2 && <div className={Styles.workExperience}>
                        {(!profile.noExperience && profile.experience?.length) ? profile.experience.sort((a, b) => b.to - (a.toPresent ? 3000 : a.to)).map((formation, i) => {
                            const period = formation.from + " - " + (formation.toPresent ? ' Present' : formation.to);
                            return (<div className={Styles.formation} key={i}>
                                <div className={Styles.leftSide}>
                                    {formation.from != "" && period}
                                </div>
                                <div className={Styles.rightSide}>
                                    <h3>{formation.label}</h3>
                                    <h4>{formation.school}</h4>
                                    <p>{formation.description}</p>
                                </div>
                                <div className='w-[100px] bg-nt-blue rounded-md self-start text-center'>
                                    {(formation.file && formation.file.path) &&
                                        <a href={process.env.REACT_APP_BACKEND_URL + formation.file.path} className='text-white no-underline' target='_blank'>Show File</a>}
                                </div>
                            </div>)
                        }) : ''}
                        {profile.noExperience && <h4>No work experience</h4>}

                    </div>}
                    {activeTab === 3 && <div className={Styles.certificates}>
                        {(!profile.noCertificate && profile.certificates?.length) ? profile.certificates.sort((a, b) => b.to - (a.toPresent ? 3000 : a.to)).map((formation, i) => {
                            return (<div className={Styles.formation} key={i}>
                                <div className={Styles.leftSide}>
                                    {formation.year}
                                </div>
                                <div className={Styles.rightSide}>
                                    <h3>{formation.label}</h3>
                                    <p>{formation.description}</p>
                                </div>
                                <div className='w-[100px] bg-nt-blue rounded-md self-start text-center'>
                                    {(formation.file && formation.file.path) &&
                                        <a href={process.env.REACT_APP_BACKEND_URL + formation.file.path} className='text-white no-underline' target='_blank'>Show File</a>}
                                </div>
                            </div>)
                        }) : ''}
                        {profile.noCertificate && <h4>No certificates</h4>}
                    </div>}
                </div>
            </div>
        </div>
    )
}

export { SingleTutor }