import { useEffect, useState } from "react"
import { ProfileOverview } from "../../components/profile/overview"
import { SearchTutorBar } from "../../components/profile/searchTutorBar"
// import tutors from '../../utils/fake/tutors.json'
import { useDispatch } from "react-redux"
import { getTutorList } from "../../store/profile/profileActions"
import TutorsNotFoundImg from '../../assets/tutors/not-found.png'
const TutorList = ({ }) => {
    const dispatch = useDispatch();
    const [tutors, setTutors] = useState([])

    useEffect(() => {
        dispatch(getTutorList())
            .unwrap()
            .then(v => setTutors(v))
    }, [])

    const TutorsNotFound = () => {
        return (
            <div className="opacity-60 flex flex-col items-center justify-center">
                <img src={TutorsNotFoundImg} />
                <h4 className="text-nt-blue">No Tutors Found</h4>
            </div>
        )
    }
    return (
        <div className='2xl:w-4/6 mx-auto'>
            <div className='w-full'>
                <SearchTutorBar setTutors={setTutors} />
                {tutors.length > 0 ? tutors.map((v, i) =>
                    <div className="my-5" key={i}>
                        <ProfileOverview profile={v} />
                    </div>) : <TutorsNotFound />}
            </div>
        </div>
    )
}

export { TutorList }