import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FileInput, SelectInput, TextInput, PhoneInput } from "../../components/inputs";
import countries from '../../utils/data/countries.json'
import timezones from '../../utils/data/timezones.json'
import { getImage, successToast } from "../../utils";
import { createAdministrator, updateUser } from "../../store/auth/authActions";
import { useNavigate } from "react-router";

const CreateAdmin = () => {

    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        watch,
        setError,
        control,
        formState: { errors },
    } = useForm();
    
    const navigate = useNavigate()
    const onSubmit = async (data) => {
        console.log("data ", data);
        dispatch(createAdministrator(data))
        .then(() => navigate("/users/list"))
    };


    const options = countries.map((country) => { return { value: country.iso, label: country.name } })
    const timezoneOptions = timezones.map((timezone) => { return { value: timezone, label: timezone } })

    return (
        <>
            <form noValidate id="student-form" className={"bg-white rounded-2xl p-5 w-2/4 mx-auto flex flex-col"} onSubmit={handleSubmit(onSubmit)}>
                <FileInput
                    name="avatar"
                    label="Avatar"
                    errors={errors}
                    register={register}
                    setError={setError}
                    control={control}
                    defaultValue={watch("avatar")}
                    style="avatar"
                    required
                />

                <div className="flex gap-10 mt-5">
                    <TextInput
                        name="firstName"
                        label="First Name"
                        errors={errors}
                        register={register}
                        control={control}
                        validationSchema={{
                            required: "This field is required"
                        }}
                    />
                    <TextInput
                        name="lastName"
                        label="Last Name"
                        errors={errors}
                        register={register}
                        control={control}
                        validationSchema={{
                            required: "This field is required"
                        }}
                    />
                </div>

                <TextInput
                        name="email"
                        label="Email"
                        type="email"
                        errors={errors}
                        register={register}
                        control={control}
                        validationSchema={{
                            required: "This field is required"
                        }}
                    />
                <Controller
                    control={control}
                    name={"country"}

                    render={({ field }) => (
                        <SelectInput
                            name="country"
                            label="Country"
                            errors={errors}
                            register={register}
                            control={control}
                            options={options}
                            onChangeSelect={event => {
                                field.onChange(event.value)
                            }}
                            validationSchema={{
                                required: "This field is required"
                            }}
                        // required:
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={"timezone"}
                    render={({ field }) => (
                        <SelectInput
                            name="timezone"
                            label="Time zone"
                            errors={errors}
                            register={register}
                            control={control}
                            options={timezoneOptions}
                            onChangeSelect={event => {
                                field.onChange(event.value)
                            }}
                            validationSchema={{
                                required: "This field is required"
                            }}
                        // required:
                        />
                    )}
                />

                <PhoneInput
                    name="phone"
                    label="Phone"
                    className="phoneInput"
                    errors={errors}
                    register={register}
                    control={control}
                />

                <TextInput
                    name="password"
                    label="New Password"
                    className=""
                    type={"password"}
                    errors={errors}
                    register={register}
                    control={control}
                />

                <button className="bg-nt-blue text-white p-2 px-4 rounded self-center">
                    Save
                </button>

            </form>

        </>
    )
}
export { CreateAdmin }