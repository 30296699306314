import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { errorToast, successToast } from '../../utils';
import { registerUser } from '../../store/auth/authActions';

export const RegisterForm = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.auth);
    const [validationError, setValidationError] = useState('');
    let firstName = useRef('');
    let lastName = useRef('');
    let email = useRef('');
    let password = useRef('');

    const signUpUser = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (
            !firstName.value.trim()
            || !lastName.value.trim()
            || !email.value.trim()
            || !password.value.trim()
        ) {
            setValidationError('All fields are required');
        } else {
            dispatch(registerUser({
                firstName: firstName.value,
                lastName: lastName.value,
                email: email.value,
                password: password.value,
            }))
                .unwrap()
                .catch((errorData) => {
                    errorToast(errorData.error);
                });
        }
    };

    return (
        <form onSubmit={signUpUser}>
            <h1 className='text-nt-blue text-center mb-5'>Register</h1>

            <div>
                <label className="block text-primary-grey text-[13px] font-medium pb-1">First Name</label>
                <div className="relative">
                    <input
                        className="inputField mb-8 w-full"
                        name="firstName"
                        placeholder="Enter your first name"
                        id="firstName"
                        onChange={() => setValidationError('')}
                        ref={(e) => { firstName = e; }}
                        type="text"
                        required />
                </div>
            </div>
            <div>
                <label className="block text-primary-grey text-[13px] font-medium pb-1">Last Name</label>
                <div className="relative">
                    <input
                        className="inputField mb-8 w-full"
                        name="lastName"
                        placeholder="Enter your last name"
                        id="lastName"
                        onChange={() => setValidationError('')}
                        ref={(e) => { lastName = e; }}
                        type="text"
                        required />
                </div>
            </div>
            <div>
                <label className="block text-primary-grey text-[13px] font-medium pb-1">Email</label>
                <div className="relative">
                    <input
                        className="inputField mb-8 w-full"
                        name="email"
                        placeholder="Enter your email"
                        id="email"
                        onChange={() => setValidationError('')}
                        ref={(e) => { email = e; }}
                        type="email"
                        required />
                </div>
            </div>
            <div>
                <label className="block text-primary-grey text-[13px] font-medium pb-1">Password</label>
                <div className="relative">
                    <input
                        className="inputField mb-8 w-full"
                        name="password"
                        placeholder="Enter your password"
                        id="password"
                        onChange={() => setValidationError('')}
                        ref={(e) => { password = e; }}
                        type="password"
                        required />
                </div>
            </div>
            {validationError && <p className="text-left text-red-500">{validationError}</p>}
            <button type="submit" className="primaryButton">
                {loading ? 'Loading...' : 'Register'}
            </button>
            <p className="text-center mt-4">You already have an account ?<br />
                <Link to='/login' className="link">Login here</Link>
            </p>
        </form>
    );
};
