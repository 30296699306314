import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { FiChevronDown, FiChevronUp, FiPlusCircle, FiTrash2 } from "react-icons/fi";
import { useEffect, useRef, useState } from "react";


import Styles from '../auth/register/register.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { CertificateInput } from "../auth/register/certificateInput";
import { deleteTutorCertificate, fillTutorFormations } from "../../store/profile/profileActions";
import { CheckboxInput } from "../inputs/checkboxInput";
import { getProfileTutor, successToast } from "../../utils";

const TutorEducation = ({ setCurrentStep }) => {
    const dispatch = useDispatch();
    const { profiles } = useSelector((state) => state.profile);
    const tutorProfileExist = profiles.filter((v) => v.role === 2);
    const [removeSelected, setRemoveSelected] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const tutor = getProfileTutor()

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            noEducation: tutor.noEducation
        }
    });
    const {
        fields: certificateFields,
        append: certificateAppend,
        remove: certificateRemove,

    } = useFieldArray({
        control,
        name: "formations",
    });

    const formRef = useRef(null);


    const removeCertif = async (id) => {
        await dispatch(deleteTutorCertificate({ id, name: "formations" }))
            .unwrap()
            .then(() => setRemoveSelected(-1));
    }
    const Remove = ({ index, id }) => {
        if (index === removeSelected) {
            return (
                <div className="flex items-center">
                    <p className='mr-1'>Remove it ?</p>
                    <button className='mr-1' onClick={(e) => { e.preventDefault(); removeCertif(id) }}>Yes</button>
                    <button className='mr-1' onClick={(e) => { e.preventDefault(); setRemoveSelected(-1) }}>No</button>
                </div>
            )
        } else {
            return (<button type="button" onClick={() => { console.log(index); setRemoveSelected(index) }}>
                <FiTrash2 size={20} color='#df2b47' />
            </button>)
        }
    }

    const CertifElement = ({ element, index }) => {
        return (
            <div className="border-2  flex flex-col justify-between mb-2 rounded-xl p-3">
                <div className="flex justify-between items-start">
                    <h6 className="text-nt-blue">{element.label}</h6>
                    <Remove index={index} id={element._id} />
                </div>
                <span className="min-w-[150px] text-nt-blue">
                    {element.from} - {element.toPresent ? "Present" : element.to}
                </span>
                <span className="text-nt-grey">
                    {element.school}
                </span>
                <p className="text-[14px]">{element.description}</p>
            </div>
        )
    }

    const noEducation = watch("noEducation");
    const onSubmit = async (data) => {
        setIsLoading(true);
        await dispatch(fillTutorFormations(data))
            .unwrap()
            .then((data) => {
                setIsLoading(false);
                successToast("Modification applied successfully")
                if (data !== undefined && data.length == certificateFields.length) {
                    certificateRemove()
                }
            });
    };

    const addEmptyCertificate = () => {
        certificateAppend({ _id: "", label: "", from: "", to: "", file: "", description: "", school: "" });
    }
    useEffect(() => {
        if (!noEducation && (certificateFields.length === 0 && ((tutorProfileExist.length && tutorProfileExist[0].education.length === 0)))) {
            addEmptyCertificate()
        }
    }, [noEducation, tutorProfileExist])

    return (
        <div className="rounded-2xl bg-white px-5 py-3 mb-5">
            <div onClick={() => setIsOpen(!isOpen)} className={`cursor-pointer flex items-center justify-between font-bold text-nt-blue border-color-nt-grey ${isOpen && "border-b-2 mb-4 pb-2 "}`}>
                Education
                {isOpen ? <FiChevronUp /> : <FiChevronDown />}
            </div>
            <div className={isOpen ? "block" : "hidden"}>
                <form ref={formRef} id="teacher-formations" className={Styles.userProfile} onSubmit={handleSubmit(onSubmit)}>
                    <CheckboxInput
                        name="noEducation"
                        label="I don't have an education level"
                        register={register}
                        control={control}
                        style={{ marginBottom: "20px" }}
                    />

                    <div className="">
                        {(!noEducation && tutorProfileExist.length) ? tutorProfileExist[0].education?.map((el, index) => {
                            return <CertifElement element={el} key={el._id} index={index} />
                        }) : ''}
                    </div>

                    {!noEducation && certificateFields.map((field, index) => (

                        <CertificateInput
                            stored={(tutorProfileExist.length && tutorProfileExist[0].education) ? tutorProfileExist[0].education.length : 0}
                            key={field.id}
                            control={control}
                            hideFile={true}
                            register={register}
                            getValues={getValues}
                            watch={watch}
                            id={field.id}
                            remove={certificateRemove}
                            name="formations"
                            index={index}
                            errors={errors}
                        />
                    ))}

                    {!noEducation && <button
                        type="button"
                        className='mb-4 flex justify-center m-auto w-full text-center'
                        onClick={() => {
                            addEmptyCertificate()
                        }}
                    >
                        <FiPlusCircle size={30} color='#191d51' fontWeight={700} />
                    </button>}

                    <button disabled={isLoading} className="block rounded-xl mx-auto px-5 py-2 bg-nt-blue text-white self-center mt-5">
                        Save
                    </button>
                </form>
            </div>
        </div>
    )
}

export { TutorEducation }