import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { FiChevronDown, FiEdit, FiPlusSquare, FiShare, FiTrash } from 'react-icons/fi';
import { Link, useLocation } from "react-router-dom";
import Styles from './navbar.module.scss'
import { useEffect } from "react";

const MenuItem = ({ title = "", Icon, submenus = [], link = "", notification = false, menuOpen }) => {
    const location = useLocation();
    const [open, setOpen] = useState();

    useEffect(() => {
        setOpen(submenus.length && location.pathname.split("/").includes(link))
    }, [])

    return (
        <motion.div animate={open ? "open" : "closed"} className={`relative w-full`}>
            <button
                onClick={() => {setOpen((pv) => !pv); menuOpen()}}
                className={`w-full p-2 rounded flex ${location.pathname === link ? 'bg-nt-blue text-white pointer-events-none' : ''}`}
            >
                <Link className={`no-underline gap-3  w-full jus items-center flex flex-row text-nt-blue group-hover:text-white ${location.pathname === link ? ' text-white' : ''}`} to={link}>

                    <Icon />
                    <p className="flex-1 text-left font-medium">
                        {submenus.length ? title : title}
                        {submenus.length > 0 && <motion.span variants={iconVariants} className="ml-auto">
                            <FiChevronDown />
                        </motion.span>}
                    </p>
                    {/* {(notification && parseInt(notification) > 0) ?
                        <span className="bg-nt-red text-white rounded-full flex items-center justify-center leading-7 w-7 h-7">{notification}</span> : ''} */}
                </Link>
            </button>

            <motion.ul
                initial={wrapperVariants.closed}
                variants={wrapperVariants}
                className={`${Styles.childMenu} flex flex-col m-0 ml-[30px]`}
            >
                {submenus.map((menu, i) => {
                    return (<Option key={i} text={menu.text} link={menu.link} location={location} />)
                })}
            </motion.ul>
        </motion.div>
    )
}

const Option = ({ text, link, location }) => {
    return (
        <motion.li
            variants={itemVariants}
            className={`w-full cursor-pointer ${Styles.menuName} ${location.pathname === link ? Styles.active : ''}`}
        >
            {/* <motion.span variants={actionIconVariants}>
                <Icon />
            </motion.span> */}
            <Link className={Styles.subMenuLink + " no-underline text-nt-blue mb-2"} to={link}><span>{text}</span></Link>

        </motion.li>
    );
};
const wrapperVariants = {
    open: {
        scaleY: 1,
        transition: {
            when: "beforeChildren",
        },
        height: "auto",
    },
    closed: {
        scaleY: 0,
        transition: {
            when: "afterChildren",
        },
        height: 0,
    },
};

const iconVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
};

const itemVariants = {
    open: {
        opacity: 1,
        y: 0,
        transition: {
            when: "beforeChildren",
        },
    },
    closed: {
        opacity: 0,
        y: -5,
        transition: {
            when: "afterChildren",
        },
    },
};

export { MenuItem }