import { FormProvider, useForm } from "react-hook-form";
import { DaysAvailability } from "../../components/settings/daysAvaibility";
import { Tarif } from "../../components/settings/tarif";

const AvailabilitySettings = () => {
    const methods = useForm({
        defaultValues: {
            days: [
                {
                    name: "Monday",
                    slices: [
                        {
                            start: 8,
                            end: 18,
                        }
                    ],
                    off: false,
                },
                {
                    name: "Tuesday",
                    slices: [
                        {
                            start: 8,
                            end: 18,
                        }
                    ],
                    off: false,
                },
                {
                    name: "Wednesday",
                    slices: [
                        {
                            start: 8,
                            end: 18,
                        }
                    ],
                    off: false,
                },
                {
                    name: "Thursday",
                    slices: [
                        {
                            start: 8,
                            end: 18,
                        }
                    ],
                    off: false,
                },
                {
                    name: "Friday",
                    slices: [
                        {
                            start: 8,
                            end: 18,
                        }
                    ],
                    off: false,
                },
                {
                    name: "Saturday",
                    slices: [
                        {
                            start: 8,
                            end: 18,
                        }
                    ],
                    off: false,
                },
                {
                    name: "Sunday",
                    slices: [
                        {
                            start: 8,
                            end: 18,
                        }
                    ],
                    off: false,
                },
            ]
        }
    });

    const onSubmit = async (data) => {
        console.log("submit")
        console.log(data);
    };

    return (
        <div className="w-2/4 mx-auto">
            <form onSubmit={methods.handleSubmit(onSubmit)} className="bg-white p-8 rounded-2xl flex flex-col justify-center">
                <FormProvider {...methods}>
                    
                    <h2 className="text-nt-blue mb-8">Business Hours</h2>
                    <div className="flex gap-5 justify-center" >
                        <DaysAvailability {...methods} />
                    </div>
                </FormProvider>
                <input className="self-end bg-nt-blue text-white rounded p-2 mt-5" type="submit" value={"Submit"} />
            </form>
        </div>

    )
}

export { AvailabilitySettings }