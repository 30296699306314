import { FiHeadphones, FiSearch } from 'react-icons/fi'
import Styles from './chat.module.scss'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConversationList, getFullChat } from '../../store/chat/chatActions';
import { getImage } from '../../utils';
import { setCurrentChat } from '../../store/chat/chatSlice';

const Messages = ({ getMessage = false, search = true, defaultChat = null }) => {

    const [isLoading, setIsLoading] = useState(false);
    // const [chats, setChats] = useState([]);
    const dispatch = useDispatch();
    const [selectedChat, setSelectedChat] = useState(-1)
    const { currentChat, chats } = useSelector((state) => state.chat)
    const [ searchText, setSearchText]  = useState("")
    const Search = () => {
        return (
            <div className={Styles.searchContainer + " mb-5"}>
                <FiSearch className={Styles.searchIcon} />
                <input type="text" placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} className={Styles.searchInput} />
            </div>
        )
    }

    useEffect(() => {
        if (getMessage) {
            dispatch(getConversationList())
            .unwrap()
            .then((v) => {
            })
        }

    }, [])

    useEffect(() => {
        if (defaultChat) {
            handleReadMessage(defaultChat)
        }

    }, [defaultChat])

    const handleReadMessage = (id) => {
        dispatch(getFullChat(id))
        setSelectedChat(id)
    }

    const MessagesItem = ({ chat }) => {
        const isSelected = (currentChat !== null ? chat._id === currentChat._id : false);

        return (
            <div onClick={() => (!isSelected && !getMessage) && handleReadMessage(chat._id)} className={`${Styles.messageContainer} ${isSelected ? Styles.selected : ''}`}>
                {chat.support === true ? <span className={`h-[50px] w-[50px] ${isSelected ? 'bg-white' : 'bg-nt-grey-light'} rounded-full flex items-center justify-center text-nt-blue`}><FiHeadphones size={30} /></span> : <img className={Styles.avatar} src={getImage(chat.user.avatar)} />}
                <div className={Styles.content}>
                    <div className={Styles.name+ " capitalize"}>
                        {chat.support === true ? "Support" : chat.user.firstName + " " + chat.user.lastName}
                    </div>
                    {chat.message ? <div className={Styles.lastMessage}>
                        {chat.message.isFile ? "File Message" : chat.message.message}
                    </div> : ''}
                </div>
            </div>
        )
    }

    const chatsList = chats.filter(
        (v) =>
            (!v.user && "Support".includes(searchText) && v.support) ||
            (v.user && (v.user.firstName + " " + v.user.lastName).includes(searchText))
            
    )
    return (
        <>
            {search && <Search />}
            <div className={`${Styles.messageList} w-full bg-white rounded-xl`}>
                {chatsList.map((chat, i) => {
                    return <MessagesItem chat={chat} key={i} />
                })}
            </div>
        </>
    )
}

export { Messages }