import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateToServerFormat, dateToSlice, getMomentDate } from "../../utils";
import { reportSingleAppointment } from "../../store/appointment/appointmentActions";
import { Controller, useForm } from "react-hook-form";
import Calendar from "react-calendar";
import ReactSelect from "react-select";

const ReportEvent = ({ setIsReport, event, refreshParent }) => {

    const dispatch = useDispatch();

    const { handleSubmit, control, watch, register, setValue } = useForm()

    const handleReport = (data) => {
        dispatch(reportSingleAppointment({id: data._id, reason: data.reason.label, more: data.more}))
        .unwrap()
        .then(v => refreshParent())
    }

    const {currentProfile} = useSelector(state => state.profile)
    const IssueOptions = [
        ...(currentProfile.role == 2 ? [
            { value: 0, label: "Student absent" },
            { value: 5, label: "Student have a technical problem" },
            { value: 6, label: "Other" },
        ]
            : currentProfile.role == 3 ?
                [
                    { value: 0, label: "Tutor absent" },
                    { value: 5, label: "Student have a technical problem" },
                    { value: 6, label: "Other" },
                ]
                : []
        )
    ]
    const reason = watch('reason');
    return <div>
        <form className="flex flex-col gap-5" onSubmit={handleSubmit((data) => handleReport(data))}>
            <h3 className="text-nt-blue">Report Course</h3>
            <div className="flex">
                <div className="w-full">
                    <Controller
                        control={control}
                        name="reason"
                        render={({ field: { onChange, value } }) => (
                            <ReactSelect
                                className="w-full"
                                placeholder="Reason"
                                required={true}
                                onChange={onChange}
                                value={value || ''}
                                options={IssueOptions}
                            />
                        )}
                    />
                    <input name="_id" {...register("_id")} type="hidden" value={event._id} />
                    <textarea name="message" required={(reason && reason.value == 6)} {...register("more")} rows={6} className="w-full mt-5 flex-grow border-nt-grey border-2 rounded p-1" placeholder="More Informations"></textarea>
                    {/* <textarea required name="message" {...register("message")} rows={6} className="w-full mt-5 flex-grow border-nt-grey border-2 rounded p-1" placeholder="Message"></textarea> */}
                </div>
            </div>
            <div className="flex justify-center gap-5">
                <button className="w-40 bg-nt-blue text-white px-2 py-3 rounded">Report</button>
                <button type="button" className="w-40 bg-nt-grey text-white px-2 py-3 rounded" onClick={() => setIsReport(false)}>Cancel</button>
            </div>
        </form>

    </div>
}
export { ReportEvent }