import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { createTutorProfile, getUserProfiles } from "../../store/profile/profileActions";
import { SwitchTutorForm } from "../../components/auth/register/switchTutorForm";
import { getProfileTutor } from "../../utils";

const SwitchTutor = () => {
    console.log('zbvv')
    const [currentStep, setCurrentStep] = useState(3);
    const dispatch = useDispatch();

    useEffect(() => {
        let tutor = getProfileTutor();
        if(tutor === false)
           dispatch(createTutorProfile())
        else {
            if(tutor.status === 0)
                setCurrentStep (10)
        }
    }, [])

    return (
        <SwitchTutorForm currentStep={currentStep} setCurrentStep={setCurrentStep} />
    )
}

export { SwitchTutor }