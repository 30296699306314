import { useLocation } from "react-router"
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { usePagination } from "@table-library/react-table-library/pagination";
import Styles from '../users/users.module.scss'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiArrowDown, FiArrowUp, FiBookOpen, FiCreditCard, FiDollarSign, FiEye, FiFilter, FiSettings, FiSliders, FiUsers } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { resetAppointmentNotification } from "../../store/notification/notificationActions";
import { getAppointmentList } from "../../store/appointment/appointmentActions";
import { formatDateToUserTZ, getImage, getProfileStudent, getProfileTutor } from "../../utils";
import moment from "moment";
import { getTransactionList, getWalletStats } from "../../store/wallet/walletActions";
import { NumberIcon } from "../../components/widgets";
import { TfiWallet } from "react-icons/tfi";

const Wallet = () => {
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState([]);
    const [stats, setStats] = useState([]);

    const { user } = useSelector((state) => state.auth)
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getTransactionList())
            .unwrap()
            .then((v) => setNodes(v))
        dispatch(resetAppointmentNotification())
        dispatch(getWalletStats())
    }, [])

    const TransactionAmount = ({ type, amount }) => {
        if ([-1, 1].includes(type)) {
            return <div className="text-nt-blue flex">
                <FiArrowUp className="text-nt-blue" size={20} />
                {amount + " €"}
            </div>
        } else if ([-2, 0, 2, 3].includes(type)) {
            return <div className="text-nt-red flex">
                <FiArrowDown className="text-nt-red" size={20} />
                {amount + " €"}
            </div>
        }
    }
    const [nodes, setNodes] = useState([]);

    const COLUMNS = [
        {
            label: 'Transaction Date',
            renderCell: (item) => <p className="capitalize font-medium text-nt-blue">{(formatDateToUserTZ(item.createdAt, user.timezone))}</p>,
            pinLeft: true,

        },
        {
            label: 'Amount',
            renderCell: (item) => <TransactionAmount type={item.type} amount={item.amount} />,
            pinLeft: true,

        },
        {
            label: 'Type',
            renderCell: (item) => <TypeTag type={item.type} />,
            pinLeft: true,
        },
        {
            label: 'Payment Reason',
            renderCell: (item, index) => {
                return <>
                    {item.type !== -2 ? <Link className={Styles.viewBtn} to={`/appointment/detail/${item.appointment}`}>
                        <FiEye />
                        <span>View</span>
                    </Link> : ""}
                </>
            },
            pinLeft: true,

        },
    ];

    const TypeTag = ({ type }) => {
        if (type === 2 || type === 1) {
            return <span className={`bg-green text-white px-2 rounded py-1 inline-block`}>Payment</span>
        } else if (type === 0) {
            return <span className={`bg-nt-blue text-white px-2 rounded py-1 inline-block`}>Platform</span>
        } else if (type === -1) {
            return <span className={`bg-green text-white px-2 rounded py-1 inline-block`}>Refunded</span>
        } else if (type === -2) {
            return <span className={`bg-red text-white px-2 rounded py-1 inline-block`}>Withdraw</span>
        } else if (type === 3) {
            return <span className={`bg-nt-blue opacity-70 text-white px-2 rounded py-1 inline-block`}>Booking</span>
        }
    }
    const theme = useTheme([getTheme(), {
        HeaderRow: `color: #191D51;`,
        Table: `--data-table-library_grid-template-columns: auto 200px 200px 200px; width:auto;min-width:auto;min-height:auto;`
    }]);

    let data = { nodes };

    data = {
        nodes: data.nodes.filter((item) => true
            // (item.fullname.toLowerCase().includes(search.toLowerCase()) ||
            // item.date.toLowerCase().includes(search.toLowerCase())) &&
            // (filter.length && filter.includes(item.status) || filter.length === 0)
        ),
    };

    const pagination = usePagination(data, {
        state: {
            page: 0,
            size: 5,
        },
        onChange: onPaginationChange,
    });

    const pageButtonCount = 5;
    const [minVisibleBtn, setMinVisibleBtn] = useState(0)
    const [maxVisibleBtn, setMaxVisibleBtn] = useState(pageButtonCount)
    function onPaginationChange(action, state) {
        console.log(action, state);
        // let canShow = (index + pagination.state.page >= Math.floor(maxPageButton/2) || index + pagination.state.page == 0) && index + pagination.state.page + maxPageButton <= Math.floor(maxPageButton/2)
        setMinVisibleBtn(state.page - Math.floor(pageButtonCount / 2) >= 0 ? state.page - Math.floor(pageButtonCount / 2) : 0)
        setMaxVisibleBtn((minVisibleBtn + pageButtonCount) - 1)
    }

    const toggleFilter = (status) => {
        pagination.fns.onSetPage(0)
        if (filter.includes(status))
            setFilter(x => x.filter(v => v != status))
        else
            setFilter(x => [...x, status])
    }

    const { balance, expenses, income, withdraws, pending, refunded } = useSelector((state) => state.wallet)
    const { currentProfile } = useSelector((state) => state.profile)
    const tutorProfile = getProfileTutor();
    const studentProfile = getProfileStudent();
    return (
        <div className="w-full">
            <div className={`general-informations mb-5 grid ${(currentProfile.role && ((currentProfile.role == 2 && studentProfile === false) || (currentProfile.role == 3 && tutorProfile === false))) ? 'lg:grid-cols-3' : 'lg:grid-cols-4'} sm:grid-cols-2 gap-4`}>
                {currentProfile.role && currentProfile.role == 2 && <NumberIcon title="Income" number={income + "€"} Icon={FiUsers} />}
                {currentProfile.role && currentProfile.role == 2 && <NumberIcon title="Withdraws" number={withdraws + "€"} Icon={FiBookOpen} />}
                {studentProfile !== false && <NumberIcon title="Expenses" number={expenses + "€"} Icon={FiDollarSign} />}
                <NumberIcon title="Balance" number={balance + "€"} Icon={FiCreditCard} />
                {(currentProfile.role && currentProfile.role == 3 || (currentProfile.role && currentProfile.role == 2 && studentProfile === false)) && <NumberIcon title="Pending Payment" number={pending + "€"} Icon={FiDollarSign} />}
                {currentProfile.role && currentProfile.role == 3 && <NumberIcon title="Refunded" number={refunded + "€"} Icon={FiDollarSign} />}
            </div>
            <div className="bg-white p-4 rounded-2xl">
                <div className={Styles.tableHeader}>
                    <h2>Transactions List</h2>
                    {/* <label htmlFor="search">
                        <input id="search" type="text" placeholder="Search" className={Styles.search} value={search} onChange={handleSearch} />
                    </label> */}
                </div>

                <CompactTable columns={COLUMNS} layout={{ custom: true, horizontalScroll: true, }} data={data} theme={theme} pagination={pagination} />
                <div className={Styles.pagination}>
                    <div className={Styles.paginationInfos}>
                        Total pages: {pagination.state.getTotalPages(data.nodes)}
                    </div>
                    <div className={Styles.paginationButtons}>
                        <button
                            type="button"
                            disabled={minVisibleBtn === 0}
                            onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
                        >
                            {"<"}
                        </button>
                        {pagination.state.getPages(data.nodes).map((_, index) => {
                            return (minVisibleBtn <= index && maxVisibleBtn >= index) && <button
                                key={index}
                                type="button"
                                className={pagination.state.page === index ? Styles.active : ''}
                                onClick={() => pagination.fns.onSetPage(index)}
                            >
                                {index + 1}
                            </button>

                        })}
                        <button
                            type="button"
                            onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
                            disabled={pagination.state.page + Math.floor(pageButtonCount / 2) > pagination.state.getTotalPages(data.nodes)}
                        >
                            {">"}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export { Wallet }