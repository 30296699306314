import Styles from './widgets.module.scss';

const NumberIcon = ({ title, Icon, number }) => {
    return (
        <div className={Styles.numberWidget}>
            <div className={Styles.icon}>
                <Icon size={"24px"} />
            </div>
            <div className={Styles.content}>
                <h3 className={Styles.title}>{title}</h3>
                <h2 className={Styles.number}>{number}</h2>
            </div>
        </div>
    )
}

export { NumberIcon }