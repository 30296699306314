import FullCalendar from '@fullcalendar/react'

import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCalendarInfo, getSudentCalendarInfo } from '../../store/appointment/appointmentActions';
import { formatTimeZone, getDeviceTimeZone, getImage } from '../../utils';
import moment from 'moment';

const Calendar = () => {
    const cal = createRef();

    const [loading, setLoading] = useState(true)
    const [businessHours, setBusinessHours] = useState([]);
    const [events, setEvents] = useState([]);
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth)
    const { currentProfile } = useSelector((state) => state.profile)
    const [minSlice, setMinSlice] = useState(0);
    const [maxSlice, setMaxSlice] = useState(24);
    const [isOpen, setIsOpen] = useState(false);

    const getCalendarData = (isRefresh = false) => {

        if (currentProfile.role == 2) {
            console.log("abc")
            dispatch(getCalendarInfo({
                timezone: getDeviceTimeZone(),
                profile: currentProfile._id
            }))
                .unwrap()
                .then(v => {
                    setBusinessHours(v.businessHours.days)
                    setEvents([...v.events, {
                        id: 'hh',
                        start: '2024-03-12 12:00',
                        end: '2024-03-12 14:00',
                        color: '#ccc',
                        display: 'background',
                        editable: false,
                        duration: "01:00:00"
                    }])
                    const maxMin = getMinMaxSlot(v.businessHours.days)
                    console.log(v.businessHours.days);
                    // setMinSlice(maxMin.min)
                    // setMaxSlice(maxMin.max)
                    // const times = v.businessHours.days.map((v) => v.)
                    setLoading(false)
                    if (isRefresh && cal.current) {
                        let calendarApi = cal.current.getApi();
                        calendarApi.unselect()
                    }
                })
        } else {
            dispatch(getSudentCalendarInfo({
                timezone: getDeviceTimeZone(),
                profile: currentProfile._id
            }))
                .unwrap()
                .then(v => {
                    setEvents([...v.events, {
                        id: 'hh',
                        start: '2024-03-12 12:00',
                        end: '2024-03-12 14:00',
                        color: '#ccc',
                        display: 'background',
                        editable: false,
                        duration: "01:00:00"
                    }])
                    setLoading(false)
                })
        }
    }

    const getMinMaxSlot = (data) => {
        let minStart = Infinity;
        let maxEnd = -Infinity;

        data.forEach(day => {
            if (!day.off) {
                day.slices.forEach(slice => {
                    minStart = Math.min(minStart, slice.start);
                    maxEnd = Math.max(maxEnd, slice.end);
                });
            }
        });

        return {
            "min": minStart,
            "max": maxEnd
        }
    }
    useEffect(() => {
        getCalendarData()
    }, [])


    const handleEventMouseEnter = (arg) => {
        if (arg.event.extendedProps.disablePointerEvents) {
            arg.el.style.cursor = 'default';
        }
    };

    // Function to handle mouse leave event on FullCalendar event
    const handleEventMouseLeave = (arg) => {
        arg.el.style.cursor = '';
    };

    return (
        <div className='grid gap-5 w-full'>
            <div className={`${isOpen ? 'w-3/4' : 'w-full'} bg-white rounded-xl p-5 mt-5 overflow-x-scroll`}>
                <h2 className='text-nt-blue mb-5'>Calendar</h2>
                {!loading && <FullCalendar
                    viewClassNames={"min-w-[600px]"}
                    ref={cal}
                    plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                    initialView="timeGridWeek"
                    allDaySlot={false}
                    eventOverlap={false}
                    headerToolbar={{
                        left: 'prev,next',
                        center: 'title',
                        right: 'timeGridWeek,dayGridMonth'
                    }}
                    visibleRange={{
                        start: new Date(),
                        end: null
                    }}
                    slotMinTime={(minSlice < 10 ? "0" + minSlice : minSlice) + ":00:00"}
                    slotMaxTime={(maxSlice < 10 ? "0" + maxSlice : maxSlice) + ":00:00"}
                    editable={false}
                    droppable={false}
                    timeZone='UTC'
                    contentHeight={maxSlice - minSlice > 12 ? 500 : (maxSlice - minSlice) * 40}
                    defaultTimedEventDuration={"00:50:00"}
                    handleWindowResize={true}
                    displayEventEnd={false}
                    slotLabelFormat={{
                        hour: '2-digit',
                        hour12: false,
                        minute: '2-digit',
                        meridiem: false
                    }}
                    eventTimeFormat={{
                        hour: '2-digit',
                        hour12: false,
                        minute: '2-digit',
                        meridiem: false
                    }}
                    events={events}
                    unselectAuto={false}
                    selectAllow={(selectInfo) => {
                        return new Date(selectInfo.start) >= moment()
                    }}
                    dayCellClassNames={(selectInfo) => {
                        return new Date(selectInfo.start) < new Date() ? "disabled" : ""
                    }}
                    scrollTimeReset={false}
                    selectable={false}
                    slotDuration={'00:60:00'}
                    dragScroll={true}
                    slotLabelInterval={'00:10:00'}
                    businessHours={businessHours.map((v) => {
                        return [...v.slices.map((k) => ({
                            daysOfWeek: [v.day],
                            startTime: k.start < 10 ? "0" + k.start + ":00" : k.start + ":00",
                            endTime: k.end < 10 ? "0" + k.end + ":00" : k.end + ":00"
                        }))]
                    }).flat()}
                    eventMouseEnter={handleEventMouseEnter}
                    eventMouseLeave={handleEventMouseLeave}
                    eventClassNames={'pointer-events-none'}
                    // validRange={{
                    //     start: moment().startOf("week").toISOString(),
                    //     end: moment().add(1, "year").endOf("week").toISOString()
                    // }}
                    validRange={() => {
                        if (cal.current) {
                            let calendarApi = cal.current.getApi();
                            var view = calendarApi.view;
                            if (view.type === 'dayGridMonth') {
                                return {
                                    start: moment().utc().startOf("month").toISOString(),
                                    end: moment().utc().add(1, "year").endOf("month").toISOString()
                                }
                            } else if (view.type === 'timeGridWeek') {
                                return {
                                    start: moment().utc().startOf("week").toISOString(),
                                    end: moment().utc().add(1, "year").endOf("week").toISOString()
                                }
                            }
                        } else {
                            return {
                                start: moment().utc().startOf("week").toISOString(),
                                end: moment().utc().add(1, "year").endOf("week").toISOString()
                            }
                        }



                    }
                    }
                // eventClassNames={(arg) => arg.event.extendedProps.disablePointerEvents ? 'disable-pointer-events' : ''}
                />}
            </div>
        </div>

    )
}

export { Calendar }