import { useFieldArray, useForm, useFormContext } from "react-hook-form";

import { FiChevronDown, FiChevronUp, FiPlusCircle, FiTrash2 } from "react-icons/fi";

import { useEffect, useRef, useState } from "react";

import Styles from '../auth/register/register.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { CertificateInput } from "../auth/register/certificateInput";
import { CheckboxInput } from "../inputs/checkboxInput";
import { deleteTutorCertificate, fillTutorExperience } from "../../store/profile/profileActions";
import { getProfileTutor, successToast } from "../../utils";

const TutorExperience = ({ setCurrentStep, setActiveTab }) => {
    const dispatch = useDispatch();
    const { profiles } = useSelector((state) => state.profile);
    const tutorProfileExist = profiles.filter((v) => v.role === 2);
    const [removeSelected, setRemoveSelected] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const tutor = getProfileTutor()
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            noExperience: tutor.noExperience
        }
    });

    const {
        fields: certificateFields,
        append: certificateAppend,
        remove: certificateRemove
    } = useFieldArray({
        control,
        name: "experience",
    });

    const formRef = useRef();
    const nextStep = () => {
        formRef.current.requestSubmit();
    }

    const removeCertif = async (id) => {
        await dispatch(deleteTutorCertificate({ id, name: "experiences" }))
            .unwrap()
            .then(() => setRemoveSelected(-1));
    }

    const Remove = ({ index, id }) => {
        if (index === removeSelected) {
            return (
                <div className="flex items-center">
                    <p className='mr-1'>Remove it ?</p>
                    <button className='mr-1' onClick={(e) => { e.preventDefault(); removeCertif(id) }}>Yes</button>
                    <button className='mr-1' onClick={(e) => { e.preventDefault(); setRemoveSelected(-1) }}>No</button>
                </div>
            )
        } else {
            return (<button type="button" onClick={() => { console.log(index); setRemoveSelected(index) }}>
                <FiTrash2 size={20} color='#df2b47' />
            </button>)
        }
    }

    const CertifElement = ({ element, index }) => {
        return (
            <div className="border-2  flex flex-col justify-between mb-2 rounded-xl p-3">
                <div className="flex justify-between items-start">
                    <h6 className="text-nt-blue">{element.label}</h6>
                    <Remove index={index} id={element._id} />
                </div>
                <span className="min-w-[150px] text-nt-blue">
                    {element.from} - {element.toPresent ? "Present" : element.to}
                </span>
                <span className="text-nt-grey">
                    {element.school}
                </span>
                <p className="text-[14px]">{element.description}</p>
            </div>
        )
    }

    const onSubmit = async (data) => {
        setIsLoading(true);
        console.log(data)
        await dispatch(fillTutorExperience(data))
            .unwrap()
            .then((data) => {
                setIsLoading(false);
                successToast("Modification applied successfully")
                if (data !== undefined && data.length == certificateFields.length)
                    certificateRemove()
            });
    };
    const noExperience = watch("noExperience");

    const addEmptyCertificate = () => {
        certificateAppend({ _id: "", label: "", from: "", to: "", file: "", description: "", school: "" });
    }
    useEffect(() => {
        if (!noExperience && (certificateFields.length === 0 && ((tutorProfileExist.length && tutorProfileExist[0].experience.length === 0)))) {
            console.log(certificateFields.length === 0, tutorProfileExist.length && tutorProfileExist[0].experience.length === 0)
            addEmptyCertificate()
        }
    }, [noExperience, tutorProfileExist])
    return (
        <div className="rounded-2xl bg-white px-5 py-3 mb-5">
            <div onClick={() => setIsOpen(v => !v)} className={`cursor-pointer flex items-center justify-between font-bold text-nt-blue border-color-nt-grey ${isOpen && "border-b-2 mb-4 pb-2 "}`}>
                Experience
                {isOpen ? <FiChevronUp /> : <FiChevronDown />}
            </div>
            <div className={isOpen ? "block" : "hidden"}>
                <form ref={formRef} id="teacher-formations" className={Styles.userProfile} onSubmit={handleSubmit(onSubmit)}>
                    <CheckboxInput
                        name="noExperience"
                        label="I don't have work experience"
                        register={register}
                        control={control}
                        style={{ marginBottom: "20px" }}

                    />

                    <div className="">
                        {(!noExperience && tutorProfileExist.length) ? tutorProfileExist[0].experience?.map((el, index) => {
                            return <CertifElement element={el} key={el._id} index={index} />
                        }) : ''}
                    </div>

                    {!noExperience && certificateFields.map((field, index) => (
                        <CertificateInput
                            stored={(tutorProfileExist.length && tutorProfileExist[0].experience) ? tutorProfileExist[0].experience.length : 0}
                            key={field.id}
                            control={control}
                            register={register}
                            getValues={getValues}
                            watch={watch}
                            id={field.id}
                            remove={certificateRemove}
                            name="experience"
                            index={index}
                            errors={errors}
                            hideFile={true}
                            locationLabel={"Company"}
                        />
                    ))}

                    {!noExperience && <button
                        type="button"
                        className='mb-4 flex justify-center m-auto w-full text-center'
                        onClick={() => {
                            addEmptyCertificate()
                        }}
                    >
                        <FiPlusCircle size={30} color='#191d51' fontWeight={700} />
                    </button>}

                    <button disabled={isLoading} className="block rounded-xl mx-auto px-5 py-2 bg-nt-blue text-white self-center mt-5">
                        Save
                    </button>
                </form>
                
            </div>
        </div>
    )
}

export { TutorExperience }
