import { Link, useParams } from "react-router-dom"
import { SingleTutor } from "../../components/profile"
import Styles from './users.module.scss'
import { FiArrowLeft, FiCheckCircle, FiX } from "react-icons/fi";
import { useEffect, useState } from "react";
import { getTutorFullProfile, moderateTutor } from "../../store/profile/profileActions";
import { useDispatch } from "react-redux";
const TutorModerate = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const [profile, setProfile] = useState(0)
    const [activeTab, setActiveTab] = useState(0)
    const [isModerating, setIsModerating] = useState(false)
    const [moderated, setModerated] = useState(false)
    const [decision, setDecision] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        dispatch(getTutorFullProfile(params))
            .unwrap()
            .then(v => {
                setProfile({
                    ...v, ...v.userId,
                    avatar: v.userId.avatar.path,
                    formations: v.education,
                    video: v.youtube,
                    certificates: v.certificate
                })
                setIsLoading(false)
            })
    }, [])

    const handleModerateUser = (decision) => {
        setDecision(decision)
        setIsModerating(true)
    }

    const dispatchModerate = () => {
        dispatch(moderateTutor({ tutorId: params.tutorId, decision }))
            .then(() => setModerated(true))
    }

    const ModerationAction = () => {
        return !isModerating ? <div className="flex justify-center gap-5">
            <button className="text-white bg-nt-blue py-2 px-4 rounded-md" onClick={() => handleModerateUser(true)}>Accept</button>
            <button className="text-white bg-nt-red py-2 px-4 rounded-md" onClick={() => handleModerateUser(false)}>Reject</button>
        </div> : <div className="">
            <p className="text-nt-blue text-center mb-3">{decision ? "Are you sure to accept this profile ?" : "Are you sure to reject this profile ?"}</p>
            <div className="justify-center flex gap-5">
                <button onClick={() => dispatchModerate()} className="text-white bg-nt-blue py-2 px-4 rounded-md">Yes</button>
                <button onClick={() => setIsModerating(false)} className="text-nt-blue bg-nt-grey-light py-2 px-4 rounded-md">Cancel</button>
            </div>
        </div>
    }

    const ModerationDecision = () => {
        return <div className="flex flex-col items-center gap-5">
            {decision ? <FiCheckCircle size={48} className="text-nt-blue" /> : <FiX size={48} className="text-nt-red" />}
            {decision ? <h3 className="text-nt-blue">Tutor Accepted</h3> : <h3 className="text-nt-red">Tutor Rejected</h3>}
        </div>
    }

    return (
        <>
            <div className="bg-white rounded-xl mb-5">
                <ul className="flex gap-2 p-2">
                    <li className="flex no-underline"><Link className="flex no-underline gap-2 items-center text-nt-grey after:content-['>'] " to={"/users/moderation"}><FiArrowLeft />Moderation</Link></li>
                    {!isLoading && <li className="text-nt-blue capitalize">{profile.firstName + ' ' + profile.lastName}</li>}
                </ul>
            </div>

            <div className="w-full flex gap-5">
                <div className="w-4/5">
                    {!isLoading && <SingleTutor filePreview={true} profile={profile} activeTab={activeTab} setActiveTab={setActiveTab} />}
                </div>
                <div className="w-1/5 bg-white rounded-2xl p-5 ">
                    <h3 className="text-nt-blue mb-2">Action</h3>
                    <div className="text-nt-blue mb-10">
                        <h4>Informations:</h4>
                        <p>Email: {profile.email}</p>
                        <p>phone: {profile.phone}</p>
                    </div>
                    {!moderated ? <ModerationAction /> : <ModerationDecision />}
                </div>
            </div>
        </>
    )
}

export { TutorModerate }