import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { TutorInformations } from "./tutor/informations";
import { TutorProfile } from "./tutor/profile";
import { TutorEducation } from "./tutor/education";
import { TutorExperience } from "./tutor/experiences";
import { TutorCertificates } from "./tutor/certificates";
import Logo from '../../../assets/app/logo.png'
import { SingleTutor } from '../../profile/index'
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { getProfileAdmin } from "../../../utils";

const SwitchTutorForm = ({ currentStep, setCurrentStep }) => {
    const { user } = useSelector((state) => state.auth);
    const { profiles } = useSelector((state) => state.profile);
    const admin = getProfileAdmin();
    
    const initialValues = {
        firstName: user.firstName,
        lastName: user.lastName,
        userId: user._id,
        speak: [{ lang: "en", level: "C1" }],
        teach: [{ lang: "en", level: "C1" }],
        tutorId: "",
        formations: [{ _id: "", label: "", from: "", to: "", file: "", description: "" }],
        experience: [{ _id: "", label: "", from: "", to: "", file: "", description: "" }],
        certificates: [{ _id: "", label: "", year: "", file: "", description: "" }],
    }

    const [activeTab, setActiveTab] = useState(0);

    const methods = useForm({
        defaultValues: initialValues
    })

    useEffect(() => {
        const tutorProfileExist = profiles.filter((v) => v.role === 2);
        if (tutorProfileExist.length) {
            methods.reset({
                ...initialValues, ...tutorProfileExist[0], ...user,
                video: tutorProfileExist[0].youtube,
                tutorId: tutorProfileExist[0].tutorId,
                experience: initialValues.experience
            })
        }
    }, [])

    const data = methods.watch();
    const getProfileData = () => {
        const tutorProfileExist = profiles.filter((v) => v.role === 2);
        if (tutorProfileExist.length && tutorProfileExist[0].education?.length) {
            return {
                ...data,
                formations: [...data.formations, ...tutorProfileExist[0].education],
                experience: [...data.experience, ...(tutorProfileExist[0].experience && tutorProfileExist[0].experience.length ? tutorProfileExist[0].experience : [])],
                certificates: [...data.certificates, ...(tutorProfileExist[0].certificate && tutorProfileExist[0].certificate.length ? tutorProfileExist[0].certificate : [])],
            }
        }
        return data;
    }
    const Steps = () => {

        return (

            <ul className="flex items-center justify-center mb-5">
                <li className="flex flex-row items-center gap-2 after:content-['>'] after:pe-2">
                    <span className={`text-center text-white rounded leading-8 w-8 h-8 ${currentStep === 3 ? "bg-nt-blue" : 'bg-nt-grey'}`}>1</span>
                    <label className={`text-nt-blue ${currentStep === 3 ? 'block' : 'hidden'} lg:block`}>Profile</label>
                </li>
                <li className="flex flex-row items-center gap-2 after:content-['>'] after:pe-2">
                    <span className={`text-center text-white rounded leading-8 w-8 h-8 ${currentStep === 4 ? "bg-nt-blue" : 'bg-nt-grey'}`}>2</span>
                    <label className={`text-nt-blue ${currentStep === 4 ? 'block' : 'hidden'} lg:block`}>Education</label>
                </li>
                <li className="flex flex-row items-center gap-2 after:content-['>'] after:pe-2">
                    <span className={`text-center text-white rounded leading-8 w-8 h-8 ${currentStep === 5 ? "bg-nt-blue" : 'bg-nt-grey'}`}>3</span>
                    <label className={`text-nt-blue ${currentStep === 5 ? 'block' : 'hidden'} lg:block`}>Experiences</label>
                </li>
                <li className="flex flex-row items-center gap-2">
                    <span className={`text-center text-white rounded leading-8 w-8 h-8 ${currentStep === 6 ? "bg-nt-blue" : 'bg-nt-grey'}`}>4</span>
                    <label className={`text-nt-blue ${currentStep === 6 ? 'block' : 'hidden'} lg:block`}>Certifcates</label>
                </li>
            </ul>
        )
    }

    return (

        <FormProvider {...methods}>

            <div className='w-full xl:w-1/2'>
                <div className="max-w-[600px] mx-auto relative flex justify-between items-center">
                    <img className='my-10' src={Logo} />
                    <Link to="/" className="no-underline text-nt-blue flex items-center"><FiArrowLeft size={18} className="mr-1" />Dashboard</Link>
                </div>
                {currentStep !== 10 ? <Steps /> : ''}
                <div className="max-w-[600px] mx-auto">
                    {currentStep === 3 && <TutorProfile setActiveTab={setActiveTab} setCurrentStep={setCurrentStep} />}
                    {currentStep === 4 && <TutorEducation setActiveTab={setActiveTab} setCurrentStep={setCurrentStep} />}
                    {currentStep === 5 && <TutorExperience setActiveTab={setActiveTab} setCurrentStep={setCurrentStep} />}
                    {currentStep === 6 && <TutorCertificates setActiveTab={setActiveTab} setCurrentStep={setCurrentStep} />}
                    {currentStep === 10 && <div className="w-full text-center">
                        <p style={{ fontSize: "50px" }}>🎉</p>
                        {admin === false? 'Your accout is under review!' : "Your profile is Ready"}
                    </div>}
                </div>
            </div>
            <div className="hidden xl:block w-1/2 bg-nt-grey-light h-screen fixed top-0 right-0 p-[20px] overflow-auto text-nt-blue">
                <SingleTutor profile={getProfileData()} activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
        </FormProvider>
    );
}

export { SwitchTutorForm }