import React from "react";
import { Chart } from "react-google-charts";

const BarChart = ({data, options}) => {
    
    
    return (
        <Chart
            chartType="ColumnChart"
            width="100%"
            height="400px"
            data={data}
            options={options}

        />
    );
}

export { BarChart }