import { useState } from "react"
import { TutorProfile } from "../../components/settings/tutorProfile"
import { TutorEducation } from "../../components/settings/tutorEducation"
import { TutorExperience } from "../../components/settings/tutorExperience"
import { TutorCertificates } from "../../components/settings/tutorCertificates"
import { getProfileTutor } from "../../utils"
import { Toaster } from "react-hot-toast"

const ProfileSettings = () => {
    return (
        <>
            <Toaster />
            <div className="mx-auto w-2/4">
                <TutorProfile />
                <TutorEducation />
                <TutorExperience />
                <TutorCertificates />

            </div>
        </>


    )
}

export { ProfileSettings }