import { useState } from 'react'
import Styles from './profile.module.scss'

const ProfileResume = ({ profile }) => {
    const [activeTab, setActiveTab] = useState(0)
    return (
        <div className={`${Styles.profileContainer} my-5`}>
            <div className={Styles.tutorTabs}>
                <ul className={Styles.tabTitleContainer + " flex-col md:flex-row gap-4 md:gap-0"}>
                    <li onClick={() => setActiveTab(0)} className={`${Styles.tabTitle} ${activeTab === 0 ? Styles.activeTab: ''}`}>
                        About Me
                    </li>
                    <li onClick={() => setActiveTab(1)} className={`${Styles.tabTitle} ${activeTab === 1 ? Styles.activeTab: ''}`}>
                        Education
                    </li>
                    <li onClick={() => setActiveTab(2)} className={`${Styles.tabTitle} ${activeTab === 2 ? Styles.activeTab: ''}`}>
                        Experience
                    </li>
                    <li onClick={() => setActiveTab(3)} className={`${Styles.tabTitle} ${activeTab === 3 ? Styles.activeTab: ''}`}>
                        Certificates
                    </li>
                </ul>

                <div className={Styles.tabContent}>
                    {activeTab === 0 &&
                        <div className={Styles.tutorDescription} dangerouslySetInnerHTML={{ __html: profile.description }} />
                    }
                    {activeTab === 1 && <div className={Styles.education}>
                        {(!profile.noFormations && profile.education?.length) && profile.education.map((formation, i) => {
                            return (<div className={Styles.formation} key={i}>
                                <div className={Styles.leftSide}>
                                    {formation.from} - {formation.toPresent ? ' Present' : formation.to}
                                </div>
                                <div className={Styles.rightSide}>
                                    <h3>{formation.label}</h3>
                                    <h4>{formation.school}</h4>
                                    <p>{formation.description}</p>
                                </div>
                            </div>)
                        })}
                        {profile.noFormations && <h4 className='text-nt-blue'>No education level</h4>}
                    </div>}
                    {activeTab === 2 && <div className={Styles.workExperience}>
                        {(!profile.noExperience && profile.experience?.length) && profile.experience.map((formation, i) => {
                            return (<div className={Styles.formation} key={i}>
                                <div className={Styles.leftSide}>
                                    {formation.from} - {formation.toPresent ? 'Present' : formation.to}
                                </div>
                                <div className={Styles.rightSide}>
                                    <h3>{formation.label}</h3>
                                    <h4>{formation.school}</h4>
                                    <p>{formation.description}</p>
                                </div>
                            </div>)
                        })}
                        {profile.noExperience && <h4 className='text-nt-blue'>No work experience</h4>}

                    </div>}
                    {activeTab === 3 && <div className={Styles.certificates}>
                        {(!profile.noCertificate && profile.certificate?.length) && profile.certificate.map((formation, i) => {
                            return (<div className={Styles.formation} key={i}>
                                <div className={Styles.leftSide}>
                                    {formation.year}
                                </div>
                                <div className={Styles.rightSide}>
                                    <h3>{formation.label}</h3>
                                    <p>{formation.description}</p>
                                </div>
                            </div>)
                        })}
                        {profile.noCertificate && <h4 className='text-nt-blue'>No certificates</h4>}
                    </div>}
                </div>
            </div>
        </div>
    )
}
export { ProfileResume }