import { createSlice } from '@reduxjs/toolkit';
import { resetAppointmentNotification } from './notificationActions';

const initialState = {
	loading: false,
	appointments: 4,
	messages: 3,
	wallet: 2,
	support: 3,
	error: null,
	success: false,
};

const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		// setProfile: (state, action) => {
		// 	state.currentProfile = action.payload;
		// },
		// setProfiles: (state, action) => {
		// 	state.profiles = action.payload;
		// },
		// resetProfile: (state) => {
		// 	return initialState;
		// },
		// switchToAdminProfile: (state) => {
		// 	state.currentProfile = state.profiles.filter((v) => v.role === 1)[0]
		// }
	},
	extraReducers: {
		[resetAppointmentNotification.fulfilled]: (state, action) => {
			state.appointments = 0
		},
	},
});

export default notificationSlice.reducer;

// export const { setProfile, resetProfile, setProfiles } = notificationSlice.actions;
