import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dateTimeToServerFormat } from "../../utils";

const CountdownTimer = ({ target, showDays = true, text = "" }) => {
    const [remainingTime, setRemainingTime] = useState(moment.duration(target.diff(moment().utc())));

    useEffect(() => {
        const interval = setInterval(() => {
            const newRemainingTime = moment.duration(target.diff(moment().utc()));
            setRemainingTime(newRemainingTime);
        }, 1000);

        return () => clearInterval(interval);
    }, [target]);

    const days = remainingTime.days();
    const hours = remainingTime.hours();
    const minutes = remainingTime.minutes();
    const seconds = remainingTime.seconds();

    return (
        <div className="flex flex-col">
            {text.trim() != "" && <span className="flex-1 w-full text-center text-sm text-nt-grey">{text}</span>}
            <div className="flex gap-5 flex-wrap justify-center pb-5">
                {showDays ? <h3 className="text-nt-blue">{days + (days > 1 ? " days" : " day")} </h3> : ''}
                <h3 className="text-nt-blue">{hours + (hours > 1 ? " hours" : " hour")}</h3>
                <h3 className="text-nt-blue">{minutes + (minutes > 1 ? " minutes" : " minute")}</h3>
                {/* <div>{seconds} seconds</div> */}
            </div>
        </div>
    );
};
export { CountdownTimer }