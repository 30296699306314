import { useLocation } from "react-router"
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { Table, UsersListTable } from "../../components/users/table";
import { usePagination } from "@table-library/react-table-library/pagination";

import Styles from '../users/users.module.scss'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiEye, FiFilter, FiSettings, FiSliders } from "react-icons/fi";
import { fullname } from 'react-lorem-ipsum'
import { useDispatch, useSelector } from "react-redux";
import { resetAppointmentNotification } from "../../store/notification/notificationActions";
import { getAppointmentList } from "../../store/appointment/appointmentActions";
import { formatTimeZone, getImage } from "../../utils";
import moment from "moment";
const AppointmentList = () => {
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState([]);

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAppointmentList())
            .unwrap()
            .then((v) => setNodes(v))
        dispatch(resetAppointmentNotification())
    }, [])

    const { user } = useSelector(state => state.auth)
    const [hours, minutes] = formatTimeZone(user.timezone).split(':').map(Number);

    const [nodes, setNodes] = useState([]);

    const COLUMNS = [
        {
            label: 'Avatar', class: "avatar", renderCell: (item) => {
                return <img className={Styles.avatar} src={getImage(item.avatar)} />
            },
            pinLeft: true,
        },
        {
            label: 'Name',
            renderCell: (item) => <p className="capitalize font-medium text-nt-blue">{item.fullname}</p>,
            pinLeft: true,

        },
        {
            label: 'Price',
            renderCell: (item) => item.price ? item.price + " €" : '',
            pinLeft: true,

        },
        {
            label: 'Date',
            renderCell: (item) => {
                console.log(moment(item.date).parseZone().utc().add(hours, "hours").format("YYYY-MM-DD").toString())
                return moment(item.date).utc().format("YYYY-MM-DD").toString()
            },
            pinLeft: true,
        },
        {
            label: 'Time',
            renderCell: (item) => moment(item.date).parseZone().format("HH:mm").toString() + " - " + moment(item.date).parseZone().add(50, "minutes").format("HH:mm").toString(),
            pinLeft: true,

        },
        {
            label: 'Status', renderCell: (item, index) => {
                console.log(item)
                return (
                    <div className={Styles.profileList}>
                        <AppointmentStatus status={item.status} />

                    </div>
                )
            },
            pinLeft: true,

        },
        {
            label: '',
            renderCell: (item, index) => {
                return <Link className={Styles.viewBtn} to={`/appointment/detail/${item._id}`}>
                    <FiEye />
                    <span>View</span>
                </Link>
            },
            pinLeft: true,

        },
    ];

    const AppointmentStatus = ({ status }) => {
        if (status === -1) {
            return <span className={`bg-nt-blue text-white px-2 rounded py-1`}>Pending</span>
        } else if (status === 0) {
            return <span className={`bg-nt-blue text-white px-2 rounded py-1`}>Pending Payment</span>
        } else if (status === 1) {
            return <span className={`bg-green text-white px-2 rounded py-1`}>Done</span>
        } else if (status === 2) {
            return <span className={`bg-red text-white px-2 rounded py-1`}>Canceled</span>
        } else if (status === 3) {
            return <span className={`bg-green opacity-70 text-white px-2 rounded py-1`}>Under Review</span>
        } else if (status === 4) {
            return <span className={`bg-red opacity-70 text-white px-2 rounded py-1`}>Payment Rejected</span>
        }
    }
    const theme = useTheme([getTheme(), {
        HeaderRow: `color: #191D51;`,
        Table: `--data-table-library_grid-template-columns: 150px auto 200px 200px 200px 200px 200px; width:auto;min-width:auto;min-height:auto;`
    }]);

    let data = { nodes };

    data = {
        nodes: data.nodes.filter((item) =>
            (item.fullname.toLowerCase().includes(search.toLowerCase()) ||
                item.date.toLowerCase().includes(search.toLowerCase())) &&
            (filter.length && filter.includes(item.status) || filter.length === 0)
        ),
    };

    const pagination = usePagination(data, {
        state: {
            page: 0,
            size: 5,
        },
        onChange: onPaginationChange,
    });

    const pageButtonCount = 5;
    const [minVisibleBtn, setMinVisibleBtn] = useState(0)
    const [maxVisibleBtn, setMaxVisibleBtn] = useState(pageButtonCount)
    function onPaginationChange(action, state) {
        console.log(action, state);
        // let canShow = (index + pagination.state.page >= Math.floor(maxPageButton/2) || index + pagination.state.page == 0) && index + pagination.state.page + maxPageButton <= Math.floor(maxPageButton/2)
        setMinVisibleBtn(state.page - Math.floor(pageButtonCount / 2) >= 0 ? state.page - Math.floor(pageButtonCount / 2) : 0)
        setMaxVisibleBtn((minVisibleBtn + pageButtonCount) - 1)
    }

    const toggleFilter = (status) => {
        pagination.fns.onSetPage(0)
        if (filter.includes(status))
            setFilter(x => x.filter(v => v != status))
        else
            setFilter(x => [...x, status])
    }
    return (
        <div className={Styles.tableContainer}>
            <div className={Styles.tableHeader}>
                <h2>Appointments List</h2>
                <label htmlFor="search">
                    <input id="search" type="text" placeholder="Search" className={Styles.search} value={search} onChange={handleSearch} />
                </label>
            </div>
            <div className="flex flex-wrap gap-1 mb-5 items-center">
                <FiFilter className="text-nt-blue mr-2" size={18} />
                <span onClick={() => toggleFilter(-1)} className={`${filter.includes(-1) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Pending</span>
                <span onClick={() => toggleFilter(0)} className={`${filter.includes(0) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Pending Payment</span>
                <span onClick={() => toggleFilter(1)} className={`${filter.includes(1) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Done</span>
                <span onClick={() => toggleFilter(2)} className={`${filter.includes(2) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Canceled</span>
                <span onClick={() => toggleFilter(3)} className={`${filter.includes(3) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Under Review</span>
                <span onClick={() => toggleFilter(4)} className={`${filter.includes(4) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Payment Rejected</span>
            </div>
            <CompactTable columns={COLUMNS} layout={{ custom: true, horizontalScroll: true, }} data={data} theme={theme} pagination={pagination} />
            <div className={Styles.pagination}>
                <div className={Styles.paginationInfos}>
                    Total pages: {pagination.state.getTotalPages(data.nodes)}
                </div>
                <div className={Styles.paginationButtons}>
                    <button
                        type="button"
                        disabled={minVisibleBtn === 0}
                        onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
                    >
                        {"<"}
                    </button>
                    {pagination.state.getPages(data.nodes).map((_, index) => {
                        return (minVisibleBtn <= index && maxVisibleBtn >= index) && <button
                            key={index}
                            type="button"
                            className={pagination.state.page === index ? Styles.active : ''}
                            onClick={() => pagination.fns.onSetPage(index)}
                        >
                            {index + 1}
                        </button>

                    })}
                    <button
                        type="button"
                        onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
                        disabled={pagination.state.page + Math.floor(pageButtonCount / 2) > pagination.state.getTotalPages(data.nodes)}
                    >
                        {">"}
                    </button>
                </div>
            </div>
        </div>

    )
}

export { AppointmentList }