import { useForm, Controller, useFieldArray, useFormContext } from "react-hook-form";
import { SelectInput } from "../inputs"
import { CheckboxInput } from "../inputs/checkboxInput";
import { SingleDaySlices } from "./singleDaySlices";

const DaysAvailability = () => {
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    const {
        register,
        handleSubmit,
        errors,
        watch,
        control,
        setValue,
    } = useFormContext();


    const {
        fields: daysList,
    } = useFieldArray({
        control,
        name: "days",
    });

    const options = [...Array(25)].map((e, i) => {
        let time = (i < 10 ? '0' : '') + (i < 24 ? i : '00') + ':00';
        return {
            value: i,
            label: time
        }
    });

    return (
        <div className="w-full xl:w-1/2 ">
            <h2 className="mb-5 text-nt-blue">Business Hours</h2>
            <div className="hidden xl:flex border-b-2 mb-2 border-nt-grey">
                <p className="w-36">Day</p>
                <p className="w-[140px]">Start Time</p>
                <p className="w-[140px]">End Time</p>
            </div>
            <div className="flex-row items-center">
                {daysList.map((day, index) => {
                    return <SingleDaySlices options={options} name={day.name} id={index} key={index} slices={day.slices} />
                })}
            </div>
        </div>
    )
}

export { DaysAvailability }