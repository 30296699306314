import React, {
    createContext,
    useContext,
    useRef,
    useEffect,
    useState,
} from 'react'
import { useSelector } from 'react-redux'

import io from 'socket.io-client'

export const SocketContext = createContext()

export const SocketProvider = ({ children, store }) => {

    const [isConnected, setConnected] = useState(false)

    const socketUrl = `${process.env.REACT_APP_BACKEND_URL}`

    const { currentProfile } = useSelector((state) => state.profile);

    const socket = useRef(null)

    const handleOnMessage = message => {
        console.log(message)
        // store.dispatch here
    }

    useEffect(() => {
        if (!isConnected && currentProfile && currentProfile._id) {
            console.log("socket",  currentProfile._id)
            socket.current = io(socketUrl, {
                transports: ['websocket'],
                query: {
                    token: localStorage.getItem('auth_token'),
                    profile: currentProfile._id
                },
            })

            socket.current.on('connect', () => {
                console.info(`Successfully connected to socket at ${socketUrl}`)
                socket.current.emit("SOCKET_INFO", {
                    id: socket.current.id,
                    profile: currentProfile._id
                });
                setConnected(true)
            })

            socket.current.on('disconnect', () => {
                console.info(`Successfully disconnected`)
                setConnected(false)
            })

            socket.current.on('error', err => {
                console.log('Socket Error:', err.message)
            })

            socket.current.on('message', handleOnMessage)
        }

        return () => {
            if (socket.current && socket.current.connected) {
                socket.current.disconnect()
            }
        }
    }, [currentProfile])

    return (
        <SocketContext.Provider value={socket.current}>
            {children}
        </SocketContext.Provider>
    )
}

export const useSocket = () => useContext(SocketContext)