
import { useLocation } from "react-router"
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { Table, UsersListTable } from "../../components/users/table";
import { usePagination } from "@table-library/react-table-library/pagination";

import Styles from './users.module.scss'
import { useState } from "react";
import { Link } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModerationList } from "../../store/profile/profileActions";
import { getTutors } from "../../store/moderate/moderateActions";
import PhoneInput from 'react-phone-input-2'
import countriesMask from "../../utils/data/phoneMasks.json"

const TutorsModerationList = () => {
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
	// const { modetationList } = useSelector((state) => state.user);

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const [nodes, setNodes] = useState([]);
    useEffect(() => {
        dispatch(getModerationList())
        .unwrap()
        .then((v) => setNodes(v))
    },[])
    const capitalize = ([firstLetter, ...restOfWord]) => firstLetter.toUpperCase() + restOfWord.join("");

    const COLUMNS = [
        {
            label: 'Avatar', class: "avatar", renderCell: (item) => {
                return <img className={Styles.avatar} src={ process.env.REACT_APP_BACKEND_URL + item.userId.avatar.path} />
            },
        },
        {
            label: 'Firstname',
            renderCell: (item) => capitalize(item.userId.firstName)
        },
        {
            label: 'Lastname',
            renderCell: (item) => capitalize(item.userId.lastName),
        },
        {
            label: 'Phone',
            renderCell: (item) => item.userId.phone.trim() != "" ?<PhoneInput
            disabled={true}
            masks={countriesMask}
            value={item.userId.phone}
            dropdownStyle= {{display: "none"}}
            inputStyle={{border:0, paddingLeft:0, cursor: "default"}}
            buttonClass="hidden"
            countryCodeEditable={false}
            onChange={phone => this.setState({ phone })}
          /> : ""
        },
        {
            label: 'Country', renderCell: (item) => {
                return <img src={`https://flagsapi.com/${item.userId.country}/shiny/24.png`} />
            },
        },
        {
            label: '',
            renderCell: (item, index) => {
                return <Link className={Styles.viewBtn} to={`/users/moderate/${item._id}`}>
                    <FiEye />
                    <span>Moderate</span>
                </Link>
            }
        },
    ];


    const theme = useTheme([getTheme(), {
        HeaderRow: `color: #191D51;`
    }]);

    let data = { nodes };

    data = {
        nodes: data.nodes.filter((item) =>
            item.userId.firstName.toLowerCase().includes(search.toLowerCase()) ||
            item.userId.lastName.toLowerCase().includes(search.toLowerCase()) ||
            // item.userId.email.toLowerCase().includes(search.toLowerCase()) ||
            item.userId.phone?.toLowerCase().includes(search.toLowerCase())
        ),
    };

    const pagination = usePagination(data, {
        state: {
            page: 0,
            size: 5,
        },
        onChange: onPaginationChange,
    });

    const pageButtonCount = 5;
    const [minVisibleBtn, setMinVisibleBtn] = useState(0)
    const [maxVisibleBtn, setMaxVisibleBtn] = useState(pageButtonCount)
    function onPaginationChange(action, state) {
        // let canShow = (index + pagination.state.page >= Math.floor(maxPageButton/2) || index + pagination.state.page == 0) && index + pagination.state.page + maxPageButton <= Math.floor(maxPageButton/2)
        setMinVisibleBtn(state.page - Math.floor(pageButtonCount / 2) >= 0 ? state.page - Math.floor(pageButtonCount / 2) : 0)
        setMaxVisibleBtn((minVisibleBtn + pageButtonCount) - 1)
    }

    return (
        <div className={Styles.tableContainer}>
            <div className={Styles.tableHeader}>
                <h2>Moderation</h2>
                <label htmlFor="search">
                    <input id="search" type="text" placeholder="Search" className={Styles.search} value={search} onChange={handleSearch} />
                </label>
            </div>

            <CompactTable columns={COLUMNS} data={data} theme={theme} pagination={pagination} />

            <div className={Styles.pagination}>
                <div className={Styles.paginationInfos}>
                    Total pages: {pagination.state.getTotalPages(data.nodes)}
                </div>
                <div className={Styles.paginationButtons}>
                    <button
                        type="button"
                        disabled={minVisibleBtn === 0}
                        onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
                    >
                        {"<"}
                    </button>
                    {pagination.state.getPages(data.nodes).map((_, index) => {
                        return (minVisibleBtn <= index && maxVisibleBtn >= index) && <button
                            key={index}
                            type="button"
                            className={pagination.state.page === index ? Styles.active : ''}
                            onClick={() => pagination.fns.onSetPage(index)}
                        >
                            {index + 1}
                        </button>

                    })}
                    <button
                        type="button"
                        onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
                        disabled={pagination.state.page + Math.floor(pageButtonCount / 2) > pagination.state.getTotalPages(data.nodes)}
                    >
                        {">"}
                    </button>
                </div>
            </div>

        </div>
    )
}

export { TutorsModerationList }
