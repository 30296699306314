import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { logoutUser, verifyUserDetails } from '../../store/auth/authActions';
import Styles from './navbar.module.scss'
import Logo from '../../assets/app/logo.png'
import { FiHome, FiUsers, FiCalendar, FiMessageSquare, FiFolder, FiCreditCard, FiSliders, FiLogOut, FiMenu, FiX, FiHeadphones } from 'react-icons/fi';
import { getImage, getProfileStudent, getProfileTutor } from '../../utils';

import { MenuItem } from './menuItem';
import { AnimatePresence } from 'framer-motion';
import { ProfileDropdown } from './profileDropdown';
import { switchToStudentProfile, switchToTutorProfile } from '../../store/profile/profileActions';

const NavbarAdmin = () => {
	const dispatch = useDispatch();
	const { user, accessToken, refreshToken } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const tutorProfile = getProfileTutor();
	const studentProfile = getProfileStudent();
	const [menuOpen, setMenuOpen] = useState(false);

	// useEffect(() => {
	// 	if (accessToken) {
	// 		dispatch(verifyUserDetails(refreshToken));
	// 	}
	// }, []);

	const logoutHandler = () => {
		dispatch(logoutUser());
	};

	const switchToTutor = () => {
		if (tutorProfile.status === -1)
			navigate("/switch/tutor")
		else
			dispatch(switchToTutorProfile())
				.then(() => navigate("/"))
	}

	const switchToStudent = () => {
		dispatch(switchToStudentProfile())
			.then(() => navigate("/"))
	}
	const { appointments, messages, support } = useSelector(state => state.notification)


	return (

		<AnimatePresence>
			<>
				<div className='fixed md:hidden bg-white w-full flex z-50 justify-center items-center py-2'>
					<div className='md:hidden'>
						<button onClick={() => setMenuOpen((old) => !old)} className='absolute left-2 top-2 p-2 rounded-full bg-nt-blue text-white grid place-items-center shadow-md'>
							{!menuOpen ? <FiMenu size={24} /> : <FiX size={24} />}
						</button>
					</div>
					<img src={Logo} className='self-center justify-self-center' />

				</div>
				<div className={`w-[300px] overflow-y-auto h-[calc(100%-3.5rem)] md:min-h-screen rounded-tr-3xl rounded-br-3xl flex flex-col bg-white 
				items-start p-6 z-50 fixed transition-transform top-14 md:top-0
			${menuOpen ? "translate-x-0" : " -translate-x-full"} md:translate-x-0`}>
					<img src={Logo} className='hidden md:block mb-5' />
					<div className={'w-full flex-1'}>
						<ul className='space-y-3'>
							<li>
								<MenuItem menuOpen={() => setMenuOpen(false)} title="Dashboard" Icon={FiHome} link='/' />
							</li>
							<li>
								<MenuItem menuOpen={() => setMenuOpen(false)} title="Users" link={'/users/list'} Icon={FiUsers} />
							</li>
							<li>
								<MenuItem menuOpen={() => setMenuOpen(false)} title="Moderation" link={'/users/moderation'} Icon={FiUsers} />
							</li>
							<li>
								<MenuItem menuOpen={() => setMenuOpen(false)} title="Appointments" Icon={FiCalendar} link='/appointment' />
							</li>
							<li>
								<MenuItem menuOpen={() => setMenuOpen(false)} title="Chat" Icon={FiMessageSquare} link='/chat' />
							</li>
							<li>
								<MenuItem menuOpen={() => setMenuOpen(false)} title="Support" Icon={FiHeadphones} link='/admin/support' notification={support} />
							</li>
							<li>
								<MenuItem menuOpen={() => setMenuOpen(false)} title="Withdraw" Icon={FiFolder} link='/withdraw' />
							</li>
							<li>
								<MenuItem menuOpen={() => setMenuOpen(false)} title="Wallet" Icon={FiCreditCard} link='/wallet' />
							</li>
							<li>
								<MenuItem menuOpen={() => setMenuOpen(false)} title="Settings" Icon={FiSliders} link='/settings' />
							</li>
							{/* <li>
									<MenuItem title="Component2" Icon={FiSliders} link='/tutors' />
								</li> */}
						</ul>
					</div>
{/* 
					<div className='inline-table mx-auto self-end left-[25px] w-[90%] mb-5 text-sm text-white rounded overflow-hidden cursor-pointer'>
						{tutorProfile && <button className={`py-2 ${!studentProfile ? 'w-full' : 'w-1/2'} bg-nt-blue text-white`} onClick={() => switchToTutor()}>Tutor</button>}
						{studentProfile && <button className={`py-2 ${!tutorProfile ? 'w-full' : 'w-1/2'} bg-nt-grey-light text-nt-blue`} onClick={() => switchToStudent()}>Student</button>}
					</div> */}

					<div className={'flex gap-2 items-center justify-between text-sm w-full border-t-2 border-nt-grey-light pt-2'}>

						<img src={getImage(user.avatar, "AVATAR")} className='w-12 rounded-full' />
						<div className={'flex flex-col grow'}>
							<div className={'text-nt-blue font-semibold'}>{user.firstName + ' ' + user.lastName}</div>
							<div className={'text-nt-grey'}>Admin</div>
						</div>
						<div className='w-8'>
							<FiLogOut onClick={logoutHandler} className={Styles.logoutBtn} />
						</div>
					</div>


				</div>
			</>
		</AnimatePresence>
	);
};




export { NavbarAdmin };

