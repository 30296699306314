import { Link, useParams } from "react-router-dom"
import { SingleTutor } from "../../components/profile"
import Styles from './users.module.scss'
import { FiArrowLeft, FiBookOpen, FiCalendar, FiCreditCard, FiDollarSign, FiUsers } from "react-icons/fi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { NumberIcon, ProfileWidget } from "../../components/widgets";
import { useState } from "react";

const UserProfile = () => {
    const params = useParams();
    console.log(params)
    const profiles = 2;
    const [activeProfile, setActiveProfile] = useState(0)
    return (
        <>
            <div className="flex bg-white place-content-between rounded-2xl p-4">
                <ul className={`${Styles.breadcumb} flex items-center gap-2`}>
                    <li className="flex">
                        <Link className="flex items-center no-underline gap-2 text-nt-grey" to={"/users/moderation"}>
                            <FiArrowLeft />Moderation
                        </Link>
                    </li>
                    <li>Hsin mahsoon</li>
                </ul>
                <ul className="flex items-center gap-4">
                    <li>Tutor</li>
                    <li>Student</li>
                </ul>
            </div>

            <div className="mt-5">
                <div className="general-informations mb-5 grid grid-cols-5 gap-4">
                    <NumberIcon title="Attendance" number="80%" Icon={FiUsers} />
                    <NumberIcon title="Lessons" number="16542" Icon={FiBookOpen} />
                    <NumberIcon title="Appointment" number="48" Icon={FiCalendar} />
                    <NumberIcon title="Widthdrawn" number="9 852 €" Icon={FiCreditCard} />
                    <NumberIcon title="Profit" number="6 497 €" Icon={FaMoneyBillTrendUp} />
                </div>
            </div>

            <div className="mt-5">
                <ProfileWidget />
            </div>
        </>
    )
}

export { UserProfile }