import moment from "moment";
import { useDispatch } from "react-redux";
import { approveSingleAppointment, } from "../../store/appointment/appointmentActions";
import { useForm } from "react-hook-form";

const ApproveEvent = ({ setIsApprove, event, refreshParent }) => {
    const dispatch = useDispatch();
    const { handleSubmit, register } = useForm()

    const handleApprove = (data) => {
        dispatch(approveSingleAppointment({id: data._id}))
        .unwrap()
        .then(v => refreshParent())
    }

    return <div>
        <form className="flex flex-col gap-5" onSubmit={handleSubmit((data) => handleApprove(data))}>
            <input name="_id" {...register("_id")} type="hidden" value={event._id} />
            <h4 className="text-nt-blue text-center">Are you sure to approve this course?</h4>
            <div className="flex justify-center gap-5">
                <button className="w-40 bg-nt-blue text-white px-2 py-3 rounded">Yes</button>
                <button type="button" className="w-40 bg-nt-grey text-white px-2 py-3 rounded" onClick={() => setIsApprove(false)}>No</button>
            </div>
        </form>

    </div>
}
export { ApproveEvent }