/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { setCredentials } from '../store/auth/authSlice';

let store;

// Recommended approach to avoid circular import dependency error
export const injectStore = (_store) => {
	store = _store;
};

export const apiErrorResponse = (error) => {
	if (error.response) {
		console.log(error.response.data);
		console.log(error.response.status);
		console.log(error.response.headers);
	} else if (error.request) {
		console.log(error.request);
	} else {
		console.log('Error', error.message);
	}
};
const instance = axios.create({
	// baseURL: 'http://localhost:3001',
	// baseURL: 'http://localhost:3001/api/v1/',
	// baseURL: 'http://164.90.213.237:3001/api/v1/',
	baseURL: process.env.REACT_APP_BACKEND_DOMAIN,// {process.env.BACKEND_DOMAIN},
	// withCredentials: false,
	isRefresh: false,
});

instance.interceptors.request.use(
	(config) => {
		const accessToken = store.getState().auth.accessToken;
		// console.log("acc", accessToken)
		if (accessToken && !config.isRefresh) {
			config.headers = {
				Authorization: `Bearer ${accessToken}`,
				Accept: 'application/json',
			};
			// config.withCredentials = true;
		} else if (!accessToken && !config.isRefresh) {
			config.headers = {
				Accept: 'application/json',
				crossDomain: true,
			};
			config.withCredentials = false;
		}
		// config.headers['Access-Control-Allow-Origin'] = 'http://164.90.213.237:3000/';
		return config;
	},
	(err) => Promise.reject(err)
);

let calledOnce = false;

instance.interceptors.response.use((response) => {
	return response;
}, async (error) => {
	const originalRequest = error.config;
	if (error.response !== null) {
		if (error.response.status === 401 && !originalRequest._retry) {
			if (!calledOnce) {
				calledOnce = true;
				try {
					const refreshData = await instance.post('/auth/refresh');
					console.log('jjjjjj')
					if (refreshData) {
						const { user } = store.getState().auth;
						axios.defaults.headers.common.Authorization = `Bearer ${refreshData.data.token}`;
						console.log(refreshData);
						store.dispatch(setCredentials({
							user,
							accessToken: refreshData.data.token,
						}));
						console.log(refreshData);
						return instance(originalRequest);
					}
				} catch (error) {
					console.log(error)
					if (error.response && error.response.data) {
						return Promise.reject(error.response.data);
					}

					return Promise.reject(error);
				} finally {
					originalRequest._retry = true;
					calledOnce = false;
				}
			}
		}
	}

	return Promise.reject(error);
});

export default instance;
