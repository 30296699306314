import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { resetPassword, validateHash } from '../../store/auth/authActions';
import { errorToast } from '../../utils';
import Styles from '../login/login.module.scss';

import Logo from '../../assets/app/logo.png'
import { ConfirmEmail } from '../register';
const ResetPassword = () => {
	const { user, loading } = useSelector((state) => state.auth);
	const [passwordSent, setPasswordSent] = useState(false)
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let password = useRef();
	const { hash } = useParams();
	console.log(hash)
	const [isValidHash, setIsValidHash] = useState(false);
	const [isVerifyingHash, setIsVerifyingHash] = useState(true);

	useEffect(() => {
		dispatch(validateHash({ hash: hash }))
			.unwrap()
			.then((response) => {
				if (response)
					setIsValidHash(true)
				setIsVerifyingHash(false)
			})
			.catch(() => {
				setIsValidHash(false)
				setIsVerifyingHash(false)
			})
	}, []);

	const changePassword = async (e) => {
		e.preventDefault();
		console.log(hash)
		dispatch(resetPassword({
			hash: hash,
			password: password.value
		}))
			.unwrap()
			.then(() => {
				setPasswordSent(true)
			})
			.catch((errorData) => {
				errorToast(errorData.error);
			});
	};
	const ResetForm = () => {
		return (<form onSubmit={changePassword}>
			<h1 className='text-nt-blue text-center mb-5'>Reset password</h1>

			{(!passwordSent && !isVerifyingHash && isValidHash) && <div>
				<label className="block text-primary-grey text-[13px] font-medium pb-1">New Password</label>
				<div className="relative">
					<input
						className="inputField w-full mb-8"
						name="password"
						placeholder="Enter your password"
						id="password"
						ref={(e) => { password = e; }}
						type="password"
						defaultValue=""
						required />
				</div>
			</div>}
			{(!passwordSent && !isVerifyingHash && isValidHash) && <button type="submit" className="primaryButton">
				{loading ? 'Loading...' : 'Submit'}
			</button>}
			{isVerifyingHash && <p className="mb-4">
				Loading
			</p>}
			{passwordSent && <p className="mt-4">
				Your password has been successfully changed.<br />You can now <Link to='/login' className="link">login</Link> using your updated credentials.
			</p>}
			{(!isValidHash && !isVerifyingHash) && <p className="mt-4 text-center">
				The password reset link you are trying to use is either expired or invalid. <br /> <Link to='/forgot' className="link">Please initiate a new password reset request.</Link>
			</p>}
		</form>)
	}

	return (
		<>
			<Toaster />
			<ResetForm />
		</>
	);
};

export { ResetPassword }