import { FileInput, SelectInput, TextInput, TextareaInput } from '../../inputs';
import Styles from './register.module.scss';
import { Controller } from 'react-hook-form';
import { FiTrash2 } from 'react-icons/fi';
import { CheckboxInput } from '../../inputs/checkboxInput';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteTutorCertificate } from '../../../store/profile/profileActions';

const CertificateInput = ({ id, titlePlaceHolder, locationLabel = "School", index, remove, hideFile = false, watch, name, label, control, register, errors, required, stored, getValues, takeTime = true }) => {
    const certifTitle = watch(`${name}[${index}].label`, false);
    const toPresent = watch(`${name}[${index}].toPresent`, false);
    const from = watch(`${name}[${index}].from`, false);
    const currentYear = new Date().getFullYear();
    const [removeSelected, setRemoveSelected] = useState(-1)
    const dispatch = useDispatch();

    const removeCertif = async (index) => {
        const id = getValues(`${name}[${index}]._id`);

        if (id && id != "") {
            await dispatch(deleteTutorCertificate({ id, name }))
                .unwrap()
                .then((data) => {
                    remove(index)
                })
        } else {
            remove(index)
        }
    }
    const Remove = ({ index }) => {
        if (index === removeSelected) {
            return (
                <>
                    <p className='mr-1'>Remove it ?</p>
                    <button className='mr-1' onClick={(e) => { e.preventDefault(); removeCertif(index) }}>Yes</button>
                    <button className='mr-1' onClick={(e) => { e.preventDefault(); setRemoveSelected(-1) }}>No</button>
                </>
            )
        } else {
            return (<button type="button" onClick={() => { console.log(index); setRemoveSelected(index) }}>
                <FiTrash2 size={20} color='#df2b47' />
            </button>)
        }
    }
    return (
        <Controller
            control={control}
            name={`${name}[${index}]`}
            key={id}
            className={` flex`}
            render={({ field }) => (

                <div className={`${Styles.certificateContainer} flex items-center gap-x-8`} key={field.id}>
                    <div className={Styles.certifHeader}>
                        <div className={Styles.title + "break-words max-w-[70%]"}>{certifTitle === '' ? titlePlaceHolder : certifTitle}</div>
                        <div className={`${Styles.deleteBtn} flex`}>
                            {(index !== 0 || (index === 0 && stored > 0)) && <Remove index={index} />}
                        </div>
                    </div>

                    <div className={Styles.certifContent+ " w-full"}>
                        {takeTime && <TextInput
                            name={`${name}[${index}].label`}
                            label="Label"
                            errors={errors}
                            register={register}
                            control={control}
                            validationSchema={{
                                required: "This field is required"
                            }}
                        />}
                        <div className='w-full flex gap-5'>
                            <div className={`${!hideFile ? 'w-2/3' : 'w-full'}`}>
                                {takeTime && <TextInput
                                    name={`${name}[${index}].school`}
                                    label={locationLabel}
                                    errors={errors}
                                    register={register}
                                    control={control}
                                    validationSchema={{
                                        required: "This field is required",
                                    }}
                                />}
                                {!takeTime && <TextInput
                                    name={`${name}[${index}].label`}
                                    label="Label"
                                    errors={errors}
                                    register={register}
                                    control={control}
                                    validationSchema={{
                                        required: "This field is required"
                                    }}
                                />}
                                <div className='flex gap-5'>
                                    {takeTime && <TextInput
                                        name={`${name}[${index}].from`}
                                        label={"From"}
                                        errors={errors}
                                        type="number"
                                        register={register}
                                        control={control}
                                        validationSchema={{
                                            required: "This field is required",
                                            validate: (value) => {
                                                return value < 1960 ? "Minimum value is 1960" : value > currentYear ? "Maximum value is " + currentYear : true;
                                            }
                                        }}
                                    />}
                                    {!takeTime && <TextInput
                                        name={`${name}[${index}].year`}
                                        label={"Year"}
                                        errors={errors}
                                        type="number"
                                        register={register}
                                        control={control}
                                        validationSchema={{
                                            required: "This field is required"
                                        }}
                                    />}
                                    {takeTime && <div className={Styles.multipleInputs} style={{ width: "100%" }}>
                                        <TextInput
                                            name={`${name}[${index}].to`}
                                            label="To"
                                            errors={!toPresent ? errors : {}}
                                            type="number"
                                            register={register}
                                            control={control}
                                            validationSchema={{
                                                required: toPresent ? false : "This field is required",
                                                validate: (value) => {
                                                    return toPresent || (value < from ? "Minimum value is " + from : value > currentYear ? "Maximum value is " + currentYear : true);
                                                }
                                            }}
                                            minHtml={1920}
                                            maxHtml={currentYear}
                                            // required={toPresent === false}
                                            disabled={toPresent}
                                            marginBottom='0'
                                        />
                                        <CheckboxInput
                                            name={`${name}[${index}].toPresent`}
                                            label="Present"
                                            errors={errors}
                                            register={register}
                                            control={control}
                                        />
                                    </div>}
                                </div>
                            </div>

                            {!hideFile && <div className={"bg-nt-grey-light w-1/3 rounded-2xl text-center"}>
                                <FileInput
                                    name={`${name}[${index}].file`}
                                    label="Diplome"
                                    errors={errors}
                                    scssClass={Styles.certifFile}
                                    register={register}
                                    control={control}
                                    defaultValue={watch(`${name}[${index}].file`)}
                                />
                            </div>}
                        </div>

                        <div className={Styles.certifDescription}>
                            <TextareaInput
                                name={`${name}[${index}].description`}
                                id="description"
                                label="Description"
                                errors={errors}
                                register={register}
                            />
                        </div>
                        <input type='hidden' name={`${name}[${index}]._id`} value={watch(`${name}[${index}]._id`)} />
                    </div>
                </div>
            )}
        />
    );
}
export { CertificateInput };