import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getTutors = createAsyncThunk('moderate/getList', async (_, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('users/moderate/list', {}, {
			withCredentials: false
		});
		console.log(response)
		return await response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});
