import { useState } from "react"
import { UserSettings } from "./userSettings";
import { ProfileSettings } from "./profileSettings";
import { AvailabilitySettings } from "./availabilitySettings";
import { TarifSettings } from "./tarifSettings";

const TutorSettings = () => {
    const [currentTab, setCurrentTab] = useState(0);


    return (
        <div>
            <div className="">
                <ul className="flex bg-white rounded-xl p-3 px-5 justify-between mb-5 w-2/4 mx-auto">
                    <li className={currentTab === 0 ? "cursor-pointer text-nt-blue text-center w-full font-semibold": "cursor-pointer text-nt-grey text-center w-full"} onClick={() => setCurrentTab(0)}>
                        Account
                    </li>
                    <li className={currentTab === 1 ? "cursor-pointer text-nt-blue text-center w-full font-semibold": "cursor-pointer text-nt-grey text-center w-full"} onClick={() => setCurrentTab(1)}>
                        Profile
                    </li>
                    <li className={currentTab === 2 ? "cursor-pointer text-nt-blue text-center w-full font-semibold": "cursor-pointer text-nt-grey text-center w-full"} onClick={() => setCurrentTab(2)}>
                        Availability
                    </li>
                </ul>
            </div>
            {currentTab === 0 ? <UserSettings /> :
                currentTab === 1 ? <ProfileSettings /> : 
                currentTab === 2 ? <AvailabilitySettings />  : ""
            }
        </div>
    )
}
export { TutorSettings }