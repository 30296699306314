import { useEffect } from "react"
import { RegisterForm } from "../../components/auth/register"
import { useDispatch } from "react-redux"
import { getUserProfiles } from "../../store/profile/profileActions";

const CreateProfile = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUserProfiles())
    }, [])
    
    return (
        <RegisterForm />
    )
}

export { CreateProfile }