import { useSelector } from "react-redux";
import { TutorSettings } from "./tutorSettings";
import { UserSettings } from "./userSettings";

const Settings = () => {
    const { currentProfile } = useSelector((state) => state.profile);
    const { user } = useSelector((state) => state.auth);
    const role = currentProfile?.role;
    return (
        <>
            {(role === 3  || role === 1)&& <div>
                
            <h1 className="text-left text-nt-blue mb-8">User settings</h1>      
                <UserSettings />
            </div> }
            {(role === 2) && <div>
                
            <h1 className="text-left text-nt-blue mb-8">Settings</h1>      
                <TutorSettings />
            </div> }
            
        </>
    )
}

export {Settings};